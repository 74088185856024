.hr-setting-menu-card-body {
  display: flex;
  height: 66px;
  padding: var(--6, 24px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: var(--4, 16px);
  border: 2px solid var(--Gray-200, #e4e7ec);
  background: var(--Netural-White, #fff);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  .hr-setting-menu-card-left-body {
    display: flex;
    align-items: center;
    gap: 20px;

    .hr-setting-menu-card-title-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--1, 4px);

      .hr-setting-menu-card-title-detail-box {
        display: flex;
        align-items: center;
        gap: var(--2, 8px);

        .hr-setting-menu-card-title {
          color: var(--Gray-700, #344054);

          /* Display xs/medium */
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px; /* 133.333% */
        }

        .hr-setting-menu-card-title-part {
          color: var(--Gray-700, #344054);

          /* Text xl/medium */
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 150% */
        }
      }
      .hr-setting-menu-card-subtitle {
        color: var(--Gray-600, #475467);

        /* Text xl/regular */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 150% */
      }
    }
  }

  .hr-setting-menu-card-right-body {
    display: flex;
    align-items: center;
    gap: var(--2, 8px);

    .hr-setting-menu-card-approved-req-box {
      display: flex;
      padding: var(--1, 4px) var(--4, 16px);
      align-items: center;
      gap: 8px;
      border-radius: var(--Radius_Circle, 100px);
      border: 1px solid var(--Gray-200, #e4e7ec);
      background: var(--Netural-White, #fff);
      cursor: default;

      .hr-setting-menu-card-approved-req-dot {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: var(--Radius_Circle, 100px);
        background: var(--Gray-300, #d0d5dd);
        width: 6px;
        height: 6px;
      }

      .hr-setting-menu-card-approved-req-dot.use {
        border-radius: var(--Radius_Circle, 100px);
        background: var(--blue-01, #00a6ff);
      }

      .hr-setting-menu-card-approved-req {
        color: var(--Gray-700, #344054);

        /* Text sm/regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.hr-setting-menu-card-body:hover {
  border-radius: var(--4, 16px);
  border: 2px solid var(--Gray-200, #e4e7ec);
  background: var(--Brand-50, #f4feff);
}

.hr-setting-menu-card-body:focus {
  border-radius: var(--4, 16px);
  border: 2px solid var(--blue-01, #00a6ff);
  background: var(--Netural-White, #fff);
}
