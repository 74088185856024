@import "variables";

.login-page-container-body {
  display: flex;
  width: calc(100% - 200px);
  height: calc(100vh - 160px);
  padding: 80px 100px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: var(--screen-BG, #fafafa);

  .login-page-container-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--10, 40px);

    .login-page-container-top-logo img {
      display: flex;
      width: 168px;
      height: 120px;
      padding: 7.5px 0px;
      justify-content: center;
      align-items: center;
    }

    .login-page-container-top-contents-box {
      display: flex;
      width: calc(700px - 144px);
      padding: var(--14, 56px) 72px var(--16, 64px) 72px;
      flex-direction: column;
      align-items: center;
      gap: var(--10, 40px);

      border-radius: 16px;
      background: var(--White, #fff);
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .login-page-container-bottom {
    display: flex;
    padding: var(--5, 20px) 0px;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 8px);
    align-self: stretch;

    .login-page-container-bottom-contents {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 20px;

      color: var(--Gray-400, #98a2b3);
      text-align: center;
      font-variant-numeric: lining-nums tabular-nums;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

      .login-page-container-bottom-contents-stick {
        width: 2px;
        align-self: stretch;
        background: var(--Gray-200, #e4e7ec);
      }
    }
  }

  .login-page-title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--1, 4px);
    align-self: stretch;

    .login-page-title {
      color: var(--Gray-600, #475467);
      text-align: center;

      /* Display xs/semibold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }

    .login-page-sub-title {
      color: var(--Gray-500, #667085);
      text-align: center;

      /* Text md/regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}

.label-select-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;

  .label-select-helper-text {
    color: var(--Gray-500, #667085);

    /* Text sm/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}

.label-input-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  width: 100%;

  .input-bottom-text {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    align-self: stretch;
    color: var(--Brand-600, #0080db);
    text-align: right;

    /* Text sm/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

    cursor: pointer;
  }
}

.login-page-contents-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.horizontal-dash-border-line {
  height: var(--None, 0px);
  align-self: stretch;
  stroke-width: 1px;
  stroke: var(--Gray-400, #98a2b3);
}

.custom-check-box {
  width: 16px;
  height: 16px;
  appearance: none;

  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  cursor: pointer;
}

.custom-check-box:checked {
  border-radius: 4px;
  border: 1px solid var(--Brand-600, #0080db);
  background: var(--Brand-50, #f4feff);
  background-image: url("data:image/svg+xml,%3csvg%20xmlns='http://www.w3.org/2000/svg'%20width='12'%20height='12'%20viewBox='0%200%2012%2012'%20fill='none'%3e%3cpath%20d='M3%206L4.875%208.625L9.75%203.75'%20stroke='%230080DB'%20stroke-linecap='round'%20stroke-linejoin='round'/%3e%3c/svg%3e");
}

.material-inventroy-table-total-body,
.admin-table-body,
.prm-table-body,
.payroll-detail-modal-table-box,
.payroll-table-body,
.cost-code-table-body,
.cost-approval-modal-contents-box,
.initial-budget-detail-modal-body,
.change-order-table-body,
.interim-payment-table-body,
.initial-budget-table-body,
.budget-reallocation-table-body,
.cost-menu-table-body,
.activity-log-table-total-body,
.dashboard-table-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--2, 8px);

  .admin-table-title {
    padding: var(--1, 4px) var(--3, 12px);
    /* Text xl/semibold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */

    height: 40px;
    display: flex;
    align-items: center;
  }

  .admin-table-title-input {
    padding: var(--1, 4px) var(--3, 12px);
    /* Text xl/semibold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */

    display: flex;
    width: 320px;
    padding: var(--1, 4px) var(--3, 12px);
    align-items: center;
    gap: 2px;
    border-radius: var(--2x, 8px);
    border: 1px solid var(--Gray-100, #f2f4f7);
    background: var(--White, #fff);
  }

  .admin-table-box {
    display: flex;
    flex-direction: row;
    // max-height: 500px;

    .admin-table-color-line {
      width: 2px;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }

  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeader {
    border-right: 1px solid #ccc; /* 세로선 색상과 두께 조정 */
  }

  .MuiDataGrid-cell:last-child,
  .MuiDataGrid-columnHeader:last-child {
    border-right: none;
  }

  .table-text-amount-input-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;

    .table-text-amount-symbol {
      width: 20px;
    }

    .table-text-amount-number {
      width: 100%;
      text-align: center;
      @include text-ellipsis;
    }
  }

  .table-text-input-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    @include table-cell-text-default-format;

    .table-line-item-count {
      display: flex;
      align-items: center;
      height: 30px;
      box-sizing: border-box;
      padding: 5px;
    }

    .table-text-input-lead-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--2, 8px);
      align-self: stretch;
      margin-top: 4px;

      .table-text-input-lead-label {
        align-self: stretch;
        color: var(--Gray-700, #344054);
        text-align: center;

        /* Text sm/medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    div:nth-child(1),
    input:nth-child(1) {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;

      border: none;
      flex: 1;
      cursor: pointer;
    }

    // div:nth-child(1):hover {
    // @include table-cell-border-hover;
    // }

    input:nth-child(1) {
      width: 100%;
    }

    div:nth-child(2) {
      // width: 50px;
      @include center-flex;
      cursor: pointer;
    }
  }

  .table-text-input-box.hover-none {
    div:nth-child(1):hover {
      border: none;
    }
    cursor: default;
  }

  .MuiDataGrid-cell:has(.table-select-input-box),
  .MuiDataGrid-cell:has(.line-delete-cell-btn) {
    padding: 0px;
  }

  .MuiDataGrid-cell:has(.line-delete-cell-btn) {
    @include center-flex;
  }

  .table-select-input-box {
    height: 100;
    width: 100%;
    text-align: center;
    color: #fff;
    cursor: pointer;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .table-date-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    @include table-cell-text-default-format;

    div:nth-child(1),
    input:nth-child(1) {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;
      border: none;

      flex: 1;
      cursor: pointer;
    }

    // div:nth-child(1):hover {
    //   @include table-cell-border-hover;
    // }
  }

  .table-edit-date-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    @include table-cell-text-default-format;
  }

  .table-user-icon-box {
    @include center-flex;

    height: 100%;

    .table-user-icon-box-2 {
      @include table-cell-border;

      height: 30px;
      position: relative;
      cursor: pointer;
    }

    .table-user-icon {
      @include center-flex;

      box-sizing: border-box;

      width: 30px;
      height: 30px;

      border-radius: 30px;
      border: #fff solid 1px;
      background-color: #475467;

      color: #fff;
      font-size: 14px;

      position: absolute;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }

    .table-user-icon2 {
      @include center-flex;

      box-sizing: border-box;

      width: 30px;
      height: 30px;

      border-radius: 30px;
      background-color: #f9fafb;

      color: gray;
      font-size: 14px;

      position: absolute;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
  }

  .table-blank-box {
    @include center-flex;
    width: 100%;
    height: 100%;

    .table-blank-border-box {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      gap: 8px;
      cursor: pointer;
    }

    .table-blank-border-box:hover {
      // @include table-cell-border-hover;

      opacity: 1;
    }
  }
}

.select-row-control-menu-body {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 35px;

  height: 72px;
  z-index: 999;

  display: flex;
  flex-direction: row;

  border-radius: 16px;
  border: 1px solid var(--Gray-200, #e4e7ec);
  background: var(--White, #fff);

  /* popover shadow */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);

  .select-row-control-menu-left {
    @include center-flex;

    width: 72px;
    height: 72px;

    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: #0080db;

    color: var(--White, #fff);
  }

  .select-row-control-menu-center {
    flex: 1;

    display: flex;
    flex-direction: row;
    gap: 10px;

    .select-row-control-menu-center-left {
      display: flex;
      width: 400px;
      // flex: 1;
      padding: var(--3, 12px) var(--5, 20px);
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--1, 4px);
      align-self: stretch;
      overflow: hidden;

      .select-row-control-menu-select-group-title {
        color: var(--Gray-700, #344054);
        text-align: center;

        /* Display xs/regular */
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 133.333% */
      }

      .select-row-control-menu-select-group-icons {
        display: flex;
        align-items: center;
        gap: var(--1, 4px);
        overflow: hidden;

        .select-row-control-menu-select-group-icon {
          width: 6px;
          height: 6px;
          border-radius: 6px;
        }
      }
    }
    .select-row-control-menu-center-right {
      display: flex;
      padding: var(--1, 4px) var(--5, 20px);
      align-items: center;
      gap: var(--2, 8px);
      align-self: stretch;

      .detail-control-menu-icon-btn {
        display: flex;
        width: 64px;
        height: 64px;
        padding: 6px 4px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .detail-control-menu-icon {
          width: 24px;
          height: 24px;
          padding: 4px;
          color: #344054;
        }

        .detail-control-menu-icon.disabled {
          color: #d0d5dd;
        }

        .detail-control-menu-icon-name {
          color: var(--Gray-700, #344054);
          text-align: center;

          /* Text xs/medium */
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 150% */
        }

        .detail-control-menu-icon-name.disabled {
          color: #d0d5dd;
        }
      }
    }
  }

  .select-row-control-menu-right {
    @include center-flex;

    width: 72px;
    height: 72px;

    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-left: 2px solid var(--Gray-200, #e4e7ec);
  }
}

.table-amount-popover-box {
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.table-select-popover-box {
  box-sizing: border-box;
  padding: 10px 20px;
  max-height: 220px;
  // height: 100px;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;

  gap: 10px;

  .table-select-popover-item {
    @include center-flex;
    text-align: center;

    box-sizing: border-box;

    width: 100%;
    padding: 10px;
    // height: 40px;
    flex: 0 0 auto; /* Ensures the item has a fixed size */

    cursor: pointer;
    border: #fff solid 1px;
    border-radius: 5px;
    color: #fff;

    transition: all ease-in-out 0.2s;
  }

  .table-select-popover-item:hover {
    transform: scale(1.1);
  }
}

.table-user-select-popover-box {
  width: 360px;
  box-sizing: border-box;
  display: flex;
  height: 372px;
  padding: var(--3, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--3, 12px);
  align-self: stretch;
}

.select-user-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  height: 240px;

  .select-user-box-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    height: 22px;

    // padding: 5px 10px;

    border-radius: 22px;
    background: #e5f4ff;

    font-size: 12px;

    .select-user-box-item-icon,
    .select-user-box-item-close {
      @include center-flex;
      width: 22px;
      height: 22px;
    }

    .select-user-box-item-name {
      @include center-flex;
      height: 22px;
    }

    .select-user-box-item-close {
      cursor: pointer;
    }
  }
}

.select-user-list-box {
  height: 240px;
  overflow: auto;
  display: flex;
  padding: var(--1, 4px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--1, 4px);
  // flex: 1 0 0;
  align-self: stretch;

  .select-user-list-box-item {
    display: flex;
    // height: 36px;
    width: 100%;
    box-sizing: border-box;
    padding: var(--2, 8px) var(--3, 12px);
    align-items: center;
    gap: 8px;
    align-self: stretch;

    transition: all ease-in-out 0.2s;

    cursor: pointer;

    .select-user-list-box-item-icon {
      @include center-flex;
      width: 22px;
      height: 22px;
    }

    .select-user-list-box-item-text {
      color: var(--Gray-900, #101828);

      /* Text sm/medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }

  .select-user-list-box-item:hover {
    background-color: #f9fafb;
  }
}

.select-add-new-hori-line {
  display: flex;
  height: 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  width: 100%;
  background: var(--Gray-200, #e4e7ec);
}

.select-user-search-box {
  width: 100%;
  .select-user-search-input {
    height: 36px;
    box-sizing: border-box;
    display: flex;
    padding: var(--2, 8px) var(--3, 12px);
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: var(--2x, 8px);
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--White, #fff);

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    overflow: hidden;
    color: var(--Gray-700, #344054);
    text-overflow: ellipsis;
    /* Text sm/medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

.default-loadind-body {
  @include center-flex;
  width: 100%;
  height: 100%;
}

.attachment-modal-body {
  display: flex;
  flex-direction: column;

  .attachment-modal-contents-total-box {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .attachment-modal-contents-box {
      // box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
    }
  }

  .attachment-modal-material-add-body {
    width: calc(100%);
    box-sizing: border-box;
    padding: 20px;

    max-height: 500px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--7, 28px);
    align-self: stretch;
  }

  .attachment-modal-control-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    justify-content: center;
    gap: 8px;
    align-items: center;
    align-self: stretch;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .attachment-modal-submit-btn,
    .attachment-modal-cancle-btn {
      box-sizing: border-box;
      padding: 10px 16px;
      width: 110px;
      font-size: 14;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: none;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      cursor: pointer;
    }

    // "#fff", "#0080DB", "#0080DB"
    .attachment-modal-submit-btn {
      border: 1px solid #0080db;
      color: #fff;
      background: #0080db;
    }

    .attachment-modal-submit-btn:disabled,
    .attachment-modal-cancle-btn:disabled {
      border: 1px solid var(--Gray-50, #f9fafb);
      background: var(--Gray-50, #f9fafb);
      color: var(--Gray-300, #d0d5dd);
      cursor: not-allowed; /* 비활성화 커서 */
    }

    // "#344054", "#fff", "#D0D5DD"
    .attachment-modal-cancle-btn {
      border: 1px solid #d0d5dd;
      color: #344054;
      background: #fff;
    }
  }
}

.select-add-new-item-btn {
  text-transform: none;
  display: flex;
  padding: var(--2, 8px) 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  text-transform: none;
  color: var(--Gray-700, #344054);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.select-data-table-total-body {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: var(--1, 4px);
  align-self: stretch;

  .select-data-table-title-total-box {
    display: flex;
    padding: 8px 18px;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;

    .select-data-table-title-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .select-data-table-title {
        color: var(--Gray-700, #344054);

        /* Text md/medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }

    .select-data-table-search-box {
      flex: 1 0 0;
    }
  }

  .select-data-table-table-total-box {
    display: flex;
    // width: 775px;
    padding: var(--5, 20px) var(--4, 16px) var(--4, 16px) var(--4, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 16px);

    border-radius: var(--2x, 8px);
    background: var(--Gray-50, #f9fafb);

    .select-data-table-table-box {
      width: 100%;

      .MuiDataGrid-cell,
      .MuiDataGrid-columnHeader {
        border-right: 1px solid #ccc; /* 세로선 색상과 두께 조정 */
      }

      .MuiDataGrid-cell:last-child,
      .MuiDataGrid-columnHeader:last-child {
        border-right: none;
      }

      .table-text-amount-input-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;

        .table-text-amount-symbol {
          width: 20px;
        }

        .table-text-amount-number {
          width: 100%;
          text-align: center;
          @include text-ellipsis;
        }
      }

      .table-text-input-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        @include table-cell-text-default-format;

        .table-text-input-lead-box {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: var(--2, 8px);
          align-self: stretch;
          margin-top: 4px;

          .table-text-input-lead-label {
            align-self: stretch;
            color: var(--Gray-700, #344054);
            text-align: center;

            /* Text sm/medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        div:nth-child(1) {
          @include text-ellipsis;
          @include table-cell-border;
          @include table-cell-height;

          border: none;
          flex: 1;
        }
      }

      .MuiDataGrid-cell:has(.table-select-input-box),
      .MuiDataGrid-cell:has(.line-delete-cell-btn) {
        padding: 0px;
      }

      .MuiDataGrid-cell:has(.line-delete-cell-btn) {
        @include center-flex;
      }

      .table-select-input-box {
        height: 100;
        width: 100%;
        text-align: center;
        color: #fff;

        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }

      .table-date-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        @include table-cell-text-default-format;

        div:nth-child(1) {
          @include text-ellipsis;
          @include table-cell-border;
          @include table-cell-height;
          border: none;

          flex: 1;
        }
      }
    }

    .select-data-table-select-count-box {
      display: flex;
      align-items: center;
      gap: 4px;
      /* Text sm/medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */

      .select-data-table-select-count-title {
        color: var(--Gray-700, #344054);
      }

      .select-data-table-select-count {
        color: var(--Brand-600, #0080db);
      }
    }
  }
}

.check-single-select-total-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--2, 8px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--1, 4px) var(--1, 4px) var(--None, 0px) var(--None, 0px);
  border: 2px solid var(--Gray-50, #f9fafb);

  .check-single-select-title-box {
    display: flex;
    padding: var(--2, 8px) var(--3, 12px);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: var(--Gray-50, #f9fafb);

    .check-single-select-title {
      overflow: hidden;
      color: var(--Gray-700, #344054);
      text-overflow: ellipsis;

      /* Text sm/semibold */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
  }

  .check-single-select-list-box {
    .check-single-select-list {
      .check-single-select-list-btn {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: var(--2, 8px);
        flex: 1 0 0;
        align-self: stretch;
      }

      .check-single-select-list-label {
        color: var(--Gray-700, #344054) !important;
        /* Text sm/Medium */
        font-family: Inter !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 20px !important; /* 142.857% */
        word-break: break-all !important;
      }
    }
  }
}

.loading-modal-total-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;

  .loading-modal-total-box {
    @include position-fixed-centor;
    display: flex;
    box-sizing: border-box;
    width: 440px;
    // height: 184px;
    padding: var(--6, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--5, 20px);
    align-self: stretch;
    background: var(--White, #fff);

    .loading-modal-box {
      display: flex;
      padding: 0px var(--10, 40px);
      flex-direction: column;
      align-items: center;
      gap: var(--5, 20px);
      align-self: stretch;

      .loading-modal-msg-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--1, 4px);

        .loading-modal-title {
          color: var(--Gray-700, #344054);
          text-align: center;

          /* Display xs/semibold */
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px; /* 133.333% */
        }

        .loading-modal-detail-msg {
          color: var(--Gray-600, #475467);

          /* Text md/medium */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
    }
  }
}

.year-picker-input-body {
  cursor: pointer;
  display: flex;
  width: 112px;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Netural-White, #fff);

  .year-picker-input-value {
    flex: 1 0 0;
    color: var(--Gray-700, #344054);
    text-align: center;

    /* Text md/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .year-picker-input-btn-box {
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
  }
}

.month-picker-input-body {
  cursor: pointer;
  display: flex;
  width: 130px;
  padding: 6px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Netural-White, #fff);

  .month-picker-input-value {
    flex: 1 0 0;
    color: var(--Gray-700, #344054);
    text-align: center;

    /* Text md/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .month-picker-input-btn-box {
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
  }
}

.day-picker-input-body {
  cursor: pointer;
  display: flex;
  width: 130px;
  padding: 6px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Netural-White, #fff);
  box-sizing: border-box;

  .day-picker-input-value {
    flex: 1 0 0;
    color: var(--Gray-700, #344054);
    text-align: left;

    /* Text md/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .day-picker-input-btn-box {
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
  }
}

.default-modal-total-body {
  @include position-fixed-centor;

  box-sizing: border-box;
  display: flex;
  padding: var(--None, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--None, 0px);

  border: 1px solid var(--Gray-200, #e4e7ec);
  background: var(--White, #fff);

  /* popover shadow */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);

  background-color: #fff;

  .default-modal-header-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    align-items: center;
    gap: var(--None, 0px);
    align-self: stretch;
    background: var(--White, #fff);
    height: 70px;
    box-sizing: border-box;
    border-bottom: 1px solid #e4e7ec;

    .default-modal-header-title {
      display: flex;
      padding: var(--None, 0px);
      align-items: center;
      gap: var(--None, 0px);
      flex: 1 0 0;

      color: var(--Gray-700, #344054);

      /* Text md/bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
  }
}

.horizontal-solid-line {
  width: 100%;
  height: 0px;
  border-top: 2px solid #e4e7ec;
}

.vertical-solid-line {
  width: 0px;
  height: 100%;
  border-right: 2px solid #e4e7ec;
}

.company-setting-btn {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  border-radius: var(--2, 8px);
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  .company-setting-btn-label {
    color: var(--Gray-400, #98a2b3);

    /* Text sm/medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

.company-setting-btn:hover {
  background: var(--Brand-50, #f4feff);
}

.company-setting-btn:disabled {
  background: var(--Gray-50, #f9fafb);

  .company-setting-btn-label {
    color: var(--Gray-300, #d0d5dd);
  }
}

.drag-file-uploader-box {
  flex: 1 0 0;
  width: 100%;
  box-sizing: border-box;
  max-width: none;
  flex-direction: column;
  border-radius: var(--1, 4px);
  border: 1px dashed var(--Gray-400, #98a2b3);

  padding: var(--7, 28px) var(--5, 20px) var(--10, 40px) var(--5, 20px);

  align-self: stretch;
  cursor: pointer;

  .drag-file-uploader-contents-box {
    display: flex;
    gap: var(--2, 8px);
    flex-direction: column;
    align-items: center;

    .drag-file-uploader-contents-title-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--1, 4px);
      align-self: stretch;
      .drag-file-uploader-contents-title {
        color: var(--Gray-700, #344054);

        /* Text xl/medium */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 150% */
      }
      .drag-file-uploader-contents-subtitle {
        color: var(--Gray-500, #667085);
        text-align: center;

        /* Text sm/medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.default-modal-btn-group-box {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--Gray-200, #e4e7ec);

  .default-modal-btn-group-action-btn,
  .default-modal-btn-group-cancel-btn {
    box-sizing: border-box;
    padding: 10px 16px;
    width: 110px;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-transform: none;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }

  // "#fff", "#0080DB", "#0080DB"
  .default-modal-btn-group-action-btn {
    border: 1px solid #0080db;
    color: #fff;
    background: #0080db;
  }

  .default-modal-btn-group-cancel-btn {
    border: 1px solid #d0d5dd;
    color: #344054;
    background: #fff;
  }

  .default-modal-btn-group-action-btn:not(:disabled):hover {
    border: 1px solid var(--Brand-700, #0060b7);
    background: var(--Brand-700, #0060b7);
  }

  .default-modal-btn-group-cancel-btn:not(:disabled):hover {
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--Brand-50, #f4feff);
  }

  .default-modal-btn-group-action-btn:disabled,
  .default-modal-btn-group-cancel-btn:disabled {
    border: 1px solid var(--Gray-50, #f9fafb);
    background: var(--Gray-50, #f9fafb);
    cursor: not-allowed; /* 비활성화 커서 */

    color: var(--Gray-300, #d0d5dd);
  }

  // "#344054", "#fff", "#D0D5DD"
}
