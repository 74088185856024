@import "../../styles/variables.scss";

.user-setting-change-pw-body {
  @include position-fixed-centor;

  box-sizing: border-box;
  display: flex;
  width: 640px;
  padding: var(--None, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--None, 0px);

  border: 1px solid var(--Gray-200, #e4e7ec);
  background: var(--White, #fff);

  /* popover shadow */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);

  background-color: #fff;

  .user-setting-change-pw-header-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    align-items: center;
    gap: var(--None, 0px);
    align-self: stretch;
    background: var(--White, #fff);

    .user-setting-change-pw-header-title {
      display: flex;
      padding: var(--None, 0px);
      align-items: center;
      gap: var(--None, 0px);
      flex: 1 0 0;

      color: var(--Gray-700, #344054);

      /* Text md/bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
  }

  .user-setting-change-pw-main-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--5, 20px);
    align-self: stretch;

    .user-setting-change-pw-main-input-box {
      display: flex;
      padding: var(--8, 32px) var(--4, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--8, 32px);
      align-self: stretch;
      border-radius: 8px;
      background: var(--Gray-50, #f9fafb);

      .user-setting-change-pw-main-input-box-top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--4, 16px);
        align-self: stretch;
      }

      .user-setting-change-pw-main-input-box-bottom {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
      }
    }
  }

  .user-setting-change-pw-btn-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    .user-setting-change-pw-cancel-btn,
    .user-setting-change-pw-update-btn {
      box-sizing: border-box;
      padding: 10px 16px;

      font-size: 14;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: none;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      cursor: pointer;
    }

    .user-setting-change-pw-update-btn {
      border: 1px solid #0080db;
      color: #fff;
      background: #0080db;
    }

    .user-setting-change-pw-update-btn:disabled,
    .user-setting-change-pw-cancel-btn:disabled {
      border: 1px solid var(--Gray-50, #f9fafb);
      background: var(--Gray-50, #f9fafb);
      color: var(--Gray-300, #d0d5dd);
      cursor: not-allowed; /* 비활성화 커서 */
    }

    // "#344054", "#fff", "#D0D5DD"
    .user-setting-change-pw-cancel-btn {
      border: 1px solid #d0d5dd;
      color: #344054;
      background: #fff;
    }
  }
}
