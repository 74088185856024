.app-manage-body {
  height: 100%;

  .app-manage-tab-menu-body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: var(--5, 20px);
    padding-top: 20px;

    .app-manage-tab-menu-picker-area {
      display: flex;
      justify-content: flex-start;
    }
  }

  .approval-card-body {
    display: flex;
    padding: var(--6, 24px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: var(--4, 16px);
    border: 2px solid var(--Gray-200, #e4e7ec);
    background: var(--Netural-White, #fff);
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    .approval-card-left-body {
      display: flex;
      align-items: center;
      gap: 20px;
      .approval-card-title-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: var(--1, 4px);

        .approval-card-title-box {
          display: flex;
          align-items: center;
          gap: var(--2, 8px);
          .approval-card-title {
            color: var(--Gray-700, #344054);

            /* Display xs/medium */
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px; /* 133.333% */
          }

          .approval-card-title-date {
            color: var(--Gray-700, #344054);

            /* Text xl/regular */
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 150% */
          }
        }

        .approval-card-sub-title {
          color: var(--Gray-600, #475467);

          /* Text lg/medium */
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }
    }

    .approval-card-right-body {
      display: flex;
      align-items: center;
      gap: var(--2, 8px);

      .approval-card-status-body {
        display: flex;
        padding: var(--1, 4px) var(--4, 16px);
        align-items: center;
        gap: 8px;
        border-radius: var(--Radius_Circle, 100px);
        border: 1px solid var(--Gray-200, #e4e7ec);
        background: var(--Netural-White, #fff);

        .approval-card-status-dot {
          border-radius: var(--Radius_Circle, 100px);
          width: 6px;
          height: 6px;
        }

        .approval-card-status-name {
          color: var(--Gray-700, #344054);

          /* Text sm/regular */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }

      .approval-card-btn {
        display: flex;
        align-items: center;
        gap: var(--2, 8px);
      }
    }
  }

  .approval-card-body.already-approval {
    background: var(--Gray-100, #f2f4f7);

    .approval-card-title,
    .approval-card-title-date {
      color: var(--Gray-500, #667085) !important;
    }

    .approval-card-sub-title {
      color: var(--Gray-400, #98a2b3) !important;
    }
  }

  .approval-card-body:hover {
    background: var(--Brand-50, #f4feff);
  }
}

.approval-modal-contents-body {
  display: flex;
  padding: var(--4, 24px) var(--6, 36px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  // align-self: stretch;
  background: var(--White, #fff);
  flex: 1 0 0;
  width: 100%;
  box-sizing: border-box;

  .approval-modal-contents-box {
    display: flex;
    padding: var(--4, 16px) var(--3, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 16px);
    align-self: stretch;
    border-radius: var(--1, 4px);
    background: var(--Gray-50, #f9fafb);
    flex: 1 0 0;
    max-height: 587px;
    overflow-y: auto;

    .approval-modal-info-body {
      display: flex;
      width: 100%;
      align-items: flex-start;
      gap: var(--2, 8px);

      .approval-modal-info-title-box {
        display: flex;
        padding: var(--1, 4px);
        align-items: flex-start;
        gap: var(--2, 8px);
        align-self: stretch;
        width: 132px;
        flex-shrink: 0;

        .approval-modal-info-title {
          color: var(--Gray-500, #667085);

          /* Text md/medium */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }

      .approval-modal-user-info-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--1, 4px);
        align-self: stretch;
        width: calc(100% - 132px);
        box-sizing: border-box;
        // flex-shrink: 0;

        .approval-modal-user-info-detail-box {
          display: flex;
          align-items: center;
          gap: 16px;
          align-self: stretch;
          // flex-shrink: 0;

          .approval-modal-info-body {
            width: calc(50% - 8px);
            // flex-shrink: 0;
            // flex: 1 0 0;
          }
        }
      }

      .approval-modal-info-contents-box {
        display: flex;
        padding: var(--1, 4px);
        align-items: center;
        gap: var(--2, 8px);
        word-break: break-all;
        .approval-modal-info-contents {
          color: var(--Gray-700, #344054);

          /* Text md/medium */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
    }

    .approval-modal-items-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--2, 8px);
      align-self: stretch;
      .approval-modal-items-title-box {
        display: flex;
        height: 48px;
        padding: var(--1, 4px) var(--2, 8px);
        align-items: center;
        gap: 10px;
        .approval-modal-items-title {
          color: var(--Gray-700, #344054);

          /* Text md/medium */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }

      .approval-modal-items-box {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        .approval-modal-items-work-list-card {
          display: flex;
          padding: var(--1, 4px) var(--2, 8px);
          justify-content: space-between;
          align-items: flex-start;
          align-self: stretch;
          .approval-modal-items-default-text {
            color: var(--Gray-700, #344054);

            /* Text md/medium */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }

          .approval-modal-items-work-list-range-box {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            gap: var(--4, 16px);
            .approval-modal-items-default-text {
              color: var(--Gray-700, #344054);

              /* Text md/medium */
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; /* 150% */
            }
          }
        }

        .approval-modal-items-work-list-card.align-center {
          align-items: center;
        }
      }
    }

    .approval-modal-column-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--1, 4px);
      align-self: stretch;
    }

    .approval-modal-item-title-box {
      display: flex;
      height: 48px;
      padding: var(--1, 4px) var(--2, 8px);
      align-items: center;
      gap: 8px;
      align-self: stretch;
      .approval-modal-item-title {
        color: var(--Gray-700, #344054);

        /* Text md/medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }

    .approval-modal-row-wrop-body {
      display: flex;
      padding: var(--1, 4px) var(--2, 8px);
      align-items: flex-start;
      gap: var(--2, 8px);
      align-self: stretch;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .approval-modal-attachment-card {
      display: flex;
      padding: var(--2, 8px) 14px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      border-radius: var(--2, 8px);
      border: 1px solid var(--blue-01, #00a6ff);
      background: var(--Netural-White, #fff);

      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      cursor: pointer;
      transition: all 0.3s ease-in-out;

      .approval-modal-attachment-card-name {
        color: var(--Brand-600, #0080db);

        /* Text xs/medium */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
      }
    }

    .approval-modal-row-scroll-body {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: 0px var(--2, 8px);
      align-items: flex-start;
      align-self: stretch;
      overflow-x: auto;

      .approval-cards-table,
      .approval-cards-body-cell {
        border: 1px solid black;
        border-collapse: collapse;
      }

      .approval-cards-table {
        table-layout: auto; /* 테이블 레이아웃을 자동으로 설정 */
        width: auto; /* 테이블이 셀에 맞게 확장되도록 설정 */
      }

      .approval-cards-body-cell {
        padding: var(--1, 4px) var(--2, 8px);
        width: 124px;
        min-width: 124px;
        max-width: 124px;
        text-align: center;
        gap: 10px;
        align-self: stretch;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--Netural-White, #fff);
        word-break: break-all;

        .job-position-cell {
          color: var(--Gray-700, #344054);

          /* Text md/medium */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }

        .username-cell {
          color: var(--Gray-500, #667085);

          /* Text md/medium */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }

        .approval-date-cell {
          color: var(--Gray-500, #667085);

          /* Text xxs(Caption)/Regular */
          font-family: Inter;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 13px; /* 118.182% */
        }

        .approval-cards-contents-cell {
          display: flex;
          height: 72px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: var(--1, 4px);

          .approval-cards-contents {
            display: flex;
            padding: var(--1, 4px) var(--4, 16px);
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: var(--Radius_Circle, 100px);
            border: 1px solid var(--Gray-200, #e4e7ec);
            background: var(--Netural-White, #fff);

            .approval-cards-contents-dot {
              border-radius: var(--Radius_Circle, 100px);
              width: 6px;
              height: 6px;
            }

            .approval-cards-contents-name {
              color: var(--Gray-700, #344054);

              /* Text sm/regular */
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }
        }
      }

      .approval-cards-body-cell.header-cell {
        background: var(--Gray-100, #f2f4f7);
      }

      .approval-cards-body-cell.approved-cell {
        height: 80px;
      }

      .carbon-copy-card-body {
        display: flex;
        width: 200px;
        padding: 6px var(--2, 8px);
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border: 1px solid var(--Gray-300, #d0d5dd);
        border-right: none;
        background: var(--Netural-White, #fff);
        flex-shrink: 0;
        .carbon-copy-card-name {
          color: var(--Gray-700, #344054);

          /* Text md/medium */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }

      .carbon-copy-card-body:last-child {
        border-right: 1px solid var(--Gray-300, #d0d5dd);
      }
    }
  }
}

.approval-modal-btn-body {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  height: 44px;

  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-top: 1px solid #e4e7ec;

  .approval-modal-btn {
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--2, 8px);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: 140px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .approval-modal-btn-label {
      /* Text md/medium */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }

  .approval-modal-btn.normal {
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--Netural-White, #fff);
    .approval-modal-btn-label {
      color: var(--Gray-700, #344054);
    }
  }

  .approval-modal-btn.color {
    border: 1px solid var(--Brand-600, #0080db);
    background: var(--Brand-600, #0080db);
    .approval-modal-btn-label {
      color: var(--Netural-White, #fff);
    }
  }

  .approval-modal-btn.color:not(:disabled):hover {
    border: 1px solid var(--Brand-700, #0060b7);
    background: var(--Brand-700, #0060b7);
  }

  .approval-modal-btn.normal:not(:disabled):hover {
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--Brand-50, #f4feff);
  }

  .approval-modal-btn:disabled {
    border: 1px solid var(--Gray-50, #f9fafb);
    background: var(--Gray-50, #f9fafb);
    cursor: not-allowed; /* 비활성화 커서 */

    .approval-modal-btn-label {
      color: var(--Gray-300, #d0d5dd);
    }
  }
}

.cost-approval-modal-contents-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--2, 8px);

  .cost-approval-modal-contents-title-box {
    display: flex;
    width: 100%;
    height: 48px;
    padding: var(--1, 4px) 0px var(--1, 4px) var(--2, 8px);
    justify-content: space-between;
    align-items: center;
    .cost-approval-modal-contents-title {
      color: var(--Gray-700, #344054);

      /* Text md/medium */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
}

.initial-budget-detail-modal-body {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  align-self: stretch;
  height: 100%;
  width: 100%;
}

.user-info-toolip-body {
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;

  .user-info-toolip-title-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    .user-info-toolip-title {
      color: var(--Netural-White, #fff);

      /* Text xs/semibold */
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 150% */
    }
  }

  .user-info-toolip-contents-box {
    display: flex;
    padding: var(--1, 4px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--1, 4px);
    align-self: stretch;
    .user-info-toolip-contents {
      display: flex;
      align-items: center;
      gap: var(--4, 16px);
      .user-info-toolip-contents-title-box {
        width: 120px;
        .user-info-toolip-contents-title {
          color: var(--Gray-200, #e4e7ec);

          /* Text sm/medium */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }

      .user-info-toolip-contents-detail-box {
        min-width: 120px;
        .user-info-toolip-contents-detail {
          color: var(--Netural-White, #fff);
          /* Text sm/medium */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}
