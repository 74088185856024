.memu-total-body {
  height: 100%;
  padding: var(--5, 20px);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #fff;

  .menu-button-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .menu-control-botton,
    .menu-new-group-botton {
      text-transform: none;
      /* Text sm/medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      padding: 10px 16px;
      gap: 8px;
    }

    .menu-control-botton-group {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .menu-control-botton {
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;

      color: var(--Gray-700, #344054);
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);

      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .menu-new-group-botton {
      color: var(--Gray-400, #98a2b3);
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);
      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .menu-button-box-vertical-bar {
      width: 2px;
      height: 24px;
      flex-shrink: 0;
      background: var(--Gray-200, #e4e7ec);
    }
  }

  .layout-table-group-box {
    display: flex;
    flex-direction: column;
    gap: 24px;

    overflow-y: auto;
  }
}

.inven-memu-total-body {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 167px 42px 1fr;
  grid-template-columns: 360px repeat(10, 1fr);
  gap: 10px;
  background-color: #fff;
  padding: var(--5, 20px);

  .inven-memu-card-body {
    box-sizing: border-box;

    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }

  .inven-memu-card-body:nth-child(1) {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }

  .inven-memu-card-body:nth-child(2) {
    grid-row: 1 / 3;
    grid-column: 2 / 7;
  }

  .inven-memu-card-body:nth-child(3) {
    grid-row: 1 / 3;
    grid-column: 7 / 12;
  }

  .inven-memu-card-body:nth-child(4) {
    grid-row: 2 / 3;
    grid-column: 1 / 12;
  }

  .inven-memu-card-body:nth-child(5) {
    grid-row: 3 / 3;
    grid-column: 1 / 12;
    height: calc(100vh - 104px - 32px - 167px - 42px - 30px);
  }

  .material-inventroy-filter-body {
    display: flex;
    width: 360px;

    padding: var(--2, 8px) var(--None, 0px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 12px);
  }

  .inven-memu-card-body2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .material-inventroy-material-status-body {
    display: flex;
    box-sizing: border-box;
    height: 168px;
    padding: 8px 0;
    // padding: 16px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    border-radius: var(--2x, 8px);
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--White, #fff);

    .material-inventroy-material-vertical-var {
      width: 2px;
      height: 32px;
      flex-shrink: 0;
      background: var(--Gray-200, #e4e7ec);
    }

    .material-inventroy-material-status-item {
      display: flex;
      // width: 117px;
      flex: 1 0 0;
      padding: var(--1, 4px) var(--2, 8px);
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;

      .material-inventroy-material-status-title {
        align-self: stretch;
        color: var(--Gray-500, #667085);
        text-align: center;

        /* Text sm/semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }

      .material-inventroy-material-status-qty {
        align-self: stretch;
        color: var(--Gray-800, #1d2939);
        text-align: center;

        /* Text xl/semibold */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 150% */
      }
    }
  }

  .material-inventroy-table-total-body {
    height: calc(100% - 20px);
  }
}

.hr-menu-total-body {
  // height: calc(100vh - 104px - 48px);
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  padding: var(--5, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);

  .hr-menu-total-title-box {
    display: flex;
    height: 52px;
    box-sizing: border-box;
    padding: var(--2, 8px) 0px;
    align-items: center;
    gap: var(--2, 8px);
    flex-shrink: 0;
    align-self: stretch;
  }

  .hr-menu-total-title {
    overflow: hidden;
    color: var(--Gray-700, #344054);
    text-overflow: ellipsis;

    /* Display xs/semibold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }

  .hr-menu-body {
    width: 100%;
    height: calc(100% - 52px - 20px);
  }
}

.cost-menu-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;

  .cost-menu-contract-amount-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 128px;
  }

  .cost-menu-detail-body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 0 0;
  }

  .cost-menu-table-body {
    height: 100%;
  }

  .cost-menu-table-title-box {
    display: flex;
    padding: var(--1, 4px) 0px;
    align-items: center;
    gap: var(--2, 8px);
    // flex: 1 0 0;
    .cost-menu-table-title {
      overflow: hidden;
      color: var(--Gray-700, #344054);
      text-overflow: ellipsis;

      /* Text xl/semibold */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 150% */
    }
  }
}

.dashboard-menu-body {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;

  .dashboard-card {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    border-radius: var(--4, 16px);
    border: 2px solid var(--Gray-200, #e4e7ec);
    background: var(--Netural-White, #fff);

    .dashboard-title-box {
      display: flex;
      padding: var(--1, 4px) 0px;
      align-items: center;
      gap: var(--2, 8px);

      .dashboard-title {
        overflow: hidden;
        color: var(--Gray-700, #344054);
        text-overflow: ellipsis;

        /* Text xl/semibold */
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 150% */
      }
    }

    .dashboard-card-content-box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex: 1 1 0;
    }
  }
}

.material-dashboard-status-body {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  // height: 168px;
  padding: 8px 0;
  // padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  // border-radius: var(--2x, 8px);
  // border: 1px solid var(--Gray-300, #d0d5dd);
  // background: var(--White, #fff);

  .material-dashboard-vertical-var {
    width: 2px;
    height: 32px;
    flex-shrink: 0;
    background: var(--Gray-200, #e4e7ec);
  }

  .material-dashboard-status-item {
    display: flex;
    // width: 117px;
    flex: 1 0 0;
    padding: var(--1, 4px) var(--2, 8px);
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;

    .material-dashboard-status-title {
      align-self: stretch;
      color: var(--Gray-500, #667085);
      text-align: center;

      /* Text sm/semibold */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }

    .material-dashboard-status-qty {
      align-self: stretch;
      color: var(--Gray-800, #1d2939);
      text-align: center;

      /* Text xl/semibold */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 150% */
    }
  }
}
