@import "../../styles/variables.scss";

.work-policy-modal-contents-body {
  // box-sizing: border-box;
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  width: 792px;
  flex-direction: column;
  align-items: flex-start;
  // gap: var(--5, 20px);
  align-self: stretch;
}

.work-policy-modal-name-box {
  display: flex;
  padding: var(--1, 4px) 0px;
  align-items: center;
  gap: var(--2, 8px);
  align-self: stretch;
  cursor: pointer;

  .work-policy-modal-name {
    color: var(--Gray-700, #344054);

    /* Text xl/semibold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
  }
}

.work-policy-modal-name-input {
  box-sizing: border-box;
  padding: var(--1, 4px) var(--3, 12px);
  /* Text xl/semibold */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */

  display: flex;
  width: 100%;
  padding: var(--1, 4px) var(--3, 12px);
  align-items: center;
  gap: 2px;
  border-radius: var(--2x, 8px);
  border: 1px solid var(--Gray-100, #f2f4f7);
  background: var(--White, #fff);
}

.work-policy-modal-contents-box {
  display: flex;
  padding: var(--2, 8px) var(--3, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--6, 24px);
  align-self: stretch;

  .work-policy-modal-contents-title {
    display: flex;
    padding: var(--2, 8px) 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Gray-700, #344054);

    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .work-policy-modal-detail-contents-box {
    display: flex;
    padding: var(--3, 12px) var(--4, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    border-radius: var(--1, 4px);
    background: var(--Gray-50, #f9fafb);

    .work-policy-modal-dayoff-week-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--2, 8px);
      align-self: stretch;
      .work-policy-modal-dayoff-week {
        display: flex;
        padding: var(--2, 8px);
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
        row-gap: 24px;
        align-self: stretch;
        flex-wrap: wrap;
      }
    }

    .work-policy-modal-time-picker-box {
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: var(--8, 32px);
      align-self: stretch;
    }

    .work-policy-modal-time-picker-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--2, 8px);
      flex: 1 0 0;
    }

    .work-policy-modal-time-select-detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--2, 8px);
      flex: 1 0 0;
      width: 100%;
    }

    .work-policy-modal-another-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .work-policy-modal-another {
        color: var(--Gray-700, #344054);

        /* Text md/medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }
  }
}

.work-policy-modal-swicth-btn-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--1, 4px) var(--2, 8px);
  box-sizing: border-box;
}

.work-policy-modal-contents-title {
  color: black;
}

.work-policy-modal-detail-contents-box {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.work-policy-modal-check-box {
  display: flex;
  align-items: center;
  gap: 12px;

  .work-policy-modal-check-box-label {
    color: var(--Gray-700, #344054);

    /* Text md/Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
}

.work-policy-modal-btn-box {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  height: 76px;
  box-sizing: border-box;
  border-top: 1px solid #e4e7ec;

  .work-policy-modal-btn-box2 {
    display: flex;
    gap: 8px;
  }

  .work-policy-modal-cancel-btn,
  .work-policy-modal-update-btn {
    box-sizing: border-box;
    padding: 10px 16px;

    font-size: 14;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }

  .work-policy-modal-update-btn {
    border: 1px solid #0080db;
    color: #fff;
    background: #0080db;
  }

  .work-policy-modal-update-btn:hover {
    border: 1px solid var(--Brand-700, #0060b7);
    background: var(--Brand-700, #0060b7);
  }

  .work-policy-modal-update-btn:disabled,
  .work-policy-modal-cancel-btn:disabled {
    border: 1px solid var(--Gray-50, #f9fafb);
    background: var(--Gray-50, #f9fafb);
    color: var(--Gray-300, #d0d5dd);
    cursor: not-allowed; /* 비활성화 커서 */
  }

  // "#344054", "#fff", "#D0D5DD"
  .work-policy-modal-cancel-btn {
    border: 1px solid #d0d5dd;
    color: #344054;
    background: #fff;
  }
}

.select-data-table-table-box {
  width: 100%;

  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeader {
    border-right: 1px solid #ccc; /* 세로선 색상과 두께 조정 */
  }

  .MuiDataGrid-cell:last-child,
  .MuiDataGrid-columnHeader:last-child {
    border-right: none;
  }

  .table-text-amount-input-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .table-text-amount-symbol {
      width: 20px;
    }

    .table-text-amount-number {
      width: 100%;
      text-align: center;
      @include text-ellipsis;
    }
  }

  .table-text-input-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    @include table-cell-text-default-format;

    .table-text-input-lead-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--2, 8px);
      align-self: stretch;
      margin-top: 4px;

      .table-text-input-lead-label {
        align-self: stretch;
        color: var(--Gray-700, #344054);
        text-align: center;

        /* Text sm/medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    div:nth-child(1) {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;

      border: none;
      flex: 1;
    }
  }

  .MuiDataGrid-cell:has(.table-select-input-box),
  .MuiDataGrid-cell:has(.line-delete-cell-btn) {
    padding: 0px;
  }

  .MuiDataGrid-cell:has(.line-delete-cell-btn) {
    @include center-flex;
  }

  .table-select-input-box {
    height: 100;
    width: 100%;
    text-align: center;
    color: #fff;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .table-date-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    @include table-cell-text-default-format;

    div:nth-child(1) {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;
      border: none;

      flex: 1;
    }
  }
}

.work-policy-input {
  // display: flex;
  padding: 10px 6px 10px 14px;
  // align-items: center;
  gap: 8px;

  align-self: stretch;

  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Netural-White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  // outline: none;
  appearance: none;
}

.work-policy-input:disabled {
  background: var(--Gray-50, #f9fafb);
  color: var(--Gray-300, #d0d5dd);
}

.work-policy-input:focus {
  border: 1px solid var(--Brand-400, #3fc8ff);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05),
    0px 0px 0px 4px rgba(204, 250, 255, 0.5);
}

.work-policy-input::-webkit-outer-spin-button,
.work-policy-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.policy-modal-btn-body {
  display: flex;
  padding: 8px var(--4, 16px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 42px;

  cursor: pointer;

  transition: all 0.3s ease-in-out;

  border-radius: var(--2, 8px);
  border: 1px solid var(--blue-01, #00a6ff);
  background: var(--Netural-White, #fff);

  .policy-modal-btn-icon {
    width: 20px;
    height: 20px;
  }

  .policy-modal-btn-label {
    color: var(--Brand-600, #0080db);

    /* Text sm/medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

.policy-modal-btn-body:disabled {
  border: 1px solid var(--Gray-50, #f9fafb);
  background: var(--Gray-50, #f9fafb);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: not-allowed;

  .policy-modal-btn-icon {
    svg {
      path {
        fill: #d0d5dd;
      }
    }
  }

  .policy-modal-btn-label {
    color: var(--Gray-300, #d0d5dd);
  }
}

.policy-modal-btn-body:hover {
  background: var(--Brand-50, #f4feff);
}

.half-dayoff-group-btn-box {
  display: flex;
  width: 240px;
  align-items: flex-start;

  .half-dayoff-group-btn {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    height: 42px;

    border: 1px solid var(--blue-01, #00a6ff);
    background: var(--Netural-White, #fff);
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    .half-dayoff-group-btn-label {
      color: var(--Brand-600, #0080db);

      /* Text sm/medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
  }

  .half-dayoff-group-btn:nth-child(1) {
    border-radius: var(--2, 8px) var(--None, 0px) var(--None, 0px) var(--2, 8px);
    border-right-width: 0;
  }
  .half-dayoff-group-btn:nth-child(2) {
    border-radius: var(--None, 0px) 8px 8px var(--None, 0px);
  }

  .half-dayoff-group-btn.active {
    background: var(--Brand-50, #f4feff);
  }

  .half-dayoff-group-btn:hover {
    background: var(--Brand-50, #f4feff);
  }

  .half-dayoff-group-btn:disabled {
    border: 1px solid var(--Gray-50, #f9fafb);
    background: var(--Gray-50, #f9fafb);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: not-allowed;

    .half-dayoff-group-btn-label {
      color: var(--Gray-300, #d0d5dd);
    }
  }
}

.work-policy-modal-detail-contents-title {
  color: var(--Gray-700, #344054);

  /* Text md/medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.approval-modal-vertical-line {
  height: 0px;
  width: 100%;
  border-top: 2px solid var(--Gray-200, #e4e7ec);
}
