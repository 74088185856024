@import "../../styles/variables.scss";

.contact-point-modal-total-body {
  @include position-fixed-centor;

  box-sizing: border-box;
  display: flex;
  padding: var(--None, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--None, 0px);

  border: 1px solid var(--Gray-200, #e4e7ec);
  background: var(--White, #fff);

  /* popover shadow */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);

  background-color: #fff;

  .contact-point-modal-header-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    align-items: center;
    gap: var(--None, 0px);
    align-self: stretch;
    background: var(--White, #fff);
    height: 70px;
    box-sizing: border-box;
    border-bottom: 1px solid #e4e7ec;

    .contact-point-modal-header-title {
      display: flex;
      padding: var(--None, 0px);
      align-items: center;
      gap: var(--None, 0px);
      flex: 1 0 0;

      color: var(--Gray-700, #344054);

      /* Text md/bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
  }

  .contact-point-modal-main-box {
    display: flex;
    padding: var(--4, 16px) var(--8, 32px);
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  .contact-point-modal-btn-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    height: 76px;
    box-sizing: border-box;
    border-top: 1px solid #e4e7ec;

    .contact-point-modal-cancel-btn,
    .contact-point-modal-update-btn {
      box-sizing: border-box;
      padding: 10px 16px;

      font-size: 14;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: none;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      cursor: pointer;
    }

    .contact-point-modal-update-btn {
      border: 1px solid #0080db;
      color: #fff;
      background: #0080db;
    }

    .contact-point-modal-update-btn:disabled,
    .contact-point-modal-cancel-btn:disabled {
      border: 1px solid var(--Gray-50, #f9fafb);
      background: var(--Gray-50, #f9fafb);
      color: var(--Gray-300, #d0d5dd);
      cursor: not-allowed; /* 비활성화 커서 */
    }

    // "#344054", "#fff", "#D0D5DD"
    .contact-point-modal-cancel-btn {
      border: 1px solid #d0d5dd;
      color: #344054;
      background: #fff;
    }
  }
}

.contact-point-table-total-body {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: var(--1, 4px);
  align-self: stretch;

  .contact-point-table-title-total-box {
    display: flex;
    padding: 8px 18px;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;

    .contact-point-table-title-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .contact-point-table-title {
        color: var(--Gray-700, #344054);

        /* Text md/medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }

    .contact-point-table-search-box {
      flex: 1 0 0;
    }
  }

  .contact-point-table-table-total-box {
    display: flex;
    // width: 775px;
    padding: var(--5, 20px) var(--4, 16px) var(--4, 16px) var(--4, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 16px);

    border-radius: var(--2x, 8px);
    background: var(--Gray-50, #f9fafb);

    .contact-point-table-table-box {
      width: 100%;

      .MuiDataGrid-cell,
      .MuiDataGrid-columnHeader {
        border-right: 1px solid #ccc; /* 세로선 색상과 두께 조정 */
      }

      .MuiDataGrid-cell:last-child,
      .MuiDataGrid-columnHeader:last-child {
        border-right: none;
      }

      .table-text-amount-input-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .table-text-amount-symbol {
          width: 20px;
        }

        .table-text-amount-number {
          width: 100%;
          text-align: center;
          @include text-ellipsis;
        }
      }

      .table-text-input-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        @include table-cell-text-default-format;

        .table-text-input-lead-box {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: var(--2, 8px);
          align-self: stretch;
          margin-top: 4px;

          .table-text-input-lead-label {
            align-self: stretch;
            color: var(--Gray-700, #344054);
            text-align: center;

            /* Text sm/medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        div:nth-child(1) {
          @include text-ellipsis;
          @include table-cell-border;
          @include table-cell-height;

          border: none;
          flex: 1;
        }
      }

      .MuiDataGrid-cell:has(.table-select-input-box),
      .MuiDataGrid-cell:has(.line-delete-cell-btn) {
        padding: 0px;
      }

      .MuiDataGrid-cell:has(.line-delete-cell-btn) {
        @include center-flex;
      }

      .table-select-input-box {
        height: 100;
        width: 100%;
        text-align: center;
        color: #fff;

        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }

      .table-date-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        @include table-cell-text-default-format;

        div:nth-child(1) {
          @include text-ellipsis;
          @include table-cell-border;
          @include table-cell-height;
          border: none;

          flex: 1;
        }
      }
    }

    .contact-point-table-select-count-box {
      display: flex;
      align-items: center;
      gap: 4px;
      /* Text sm/medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */

      .contact-point-table-select-count-title {
        color: var(--Gray-700, #344054);
      }

      .contact-point-table-select-count {
        color: var(--Brand-600, #0080db);
      }
    }
  }
}

.contact-point-add-modal-total-body {
  @include position-fixed-centor;

  box-sizing: border-box;
  display: flex;
  padding: var(--None, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--None, 0px);

  border: 1px solid var(--Gray-200, #e4e7ec);
  background: var(--White, #fff);

  /* popover shadow */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);

  background-color: #fff;

  .contact-point-add-modal-main-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--5, 20px);
    align-self: stretch;

    .contact-point-add-modal-main {
      display: flex;
      padding: var(--4, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--4, 16px);
      align-self: stretch;

      border-radius: 8px;
      background: var(--Gray-50, #f9fafb);
    }
  }

  .contact-point-add-modal-btn-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    height: 76px;
    box-sizing: border-box;
    border-top: 1px solid #e4e7ec;

    .contact-point-add-modal-cancel-btn,
    .contact-point-add-modal-update-btn {
      box-sizing: border-box;
      padding: 10px 16px;

      font-size: 14;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: none;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      cursor: pointer;
    }

    .contact-point-add-modal-update-btn {
      border: 1px solid #0080db;
      color: #fff;
      background: #0080db;
    }

    .contact-point-add-modal-update-btn:disabled,
    .contact-point-add-modal-cancel-btn:disabled {
      border: 1px solid var(--Gray-50, #f9fafb);
      background: var(--Gray-50, #f9fafb);
      color: var(--Gray-300, #d0d5dd);
      cursor: not-allowed; /* 비활성화 커서 */
    }

    // "#344054", "#fff", "#D0D5DD"
    .contact-point-add-modal-cancel-btn {
      border: 1px solid #d0d5dd;
      color: #344054;
      background: #fff;
    }
  }
}
