.budget-change-body {
  display: flex;
  flex-direction: column;
  gap: 28px;
  height: 100%;
  width: 100%;

  .budget-change-contract-amount-body {
    height: 82px;
  }

  .budget-change-detail-body {
    flex: 1 0 0;
  }

  .budget-change-table-body {
    height: 100%;
  }
}
