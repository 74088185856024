@import "../../styles/variables.scss";

.user-perm-table-total-body {
  width: 100%;
  height: 100%;

  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeader {
    border-right: 1px solid #ccc; /* 세로선 색상과 두께 조정 */
  }

  .MuiDataGrid-cell:last-child,
  .MuiDataGrid-columnHeader:last-child {
    border-right: none;
  }

  .table-text-amount-input-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .table-text-amount-symbol {
      width: 20px;
    }

    .table-text-amount-number {
      width: 100%;
      text-align: center;
      @include text-ellipsis;
    }
  }

  .table-text-input-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    @include table-cell-text-default-format;

    .table-text-input-lead-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--2, 8px);
      align-self: stretch;
      margin-top: 4px;

      .table-text-input-lead-label {
        align-self: stretch;
        color: var(--Gray-700, #344054);
        text-align: center;

        /* Text sm/medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    div:nth-child(1),
    input:nth-child(1) {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;

      border: none;
      flex: 1;
      cursor: pointer;
    }

    div:nth-child(1):hover {
      @include table-cell-border-hover;
    }

    input:nth-child(1) {
      width: 100%;
    }

    div:nth-child(2) {
      // width: 50px;
      @include center-flex;
      cursor: pointer;
    }
  }

  .MuiDataGrid-cell:has(.table-select-input-box),
  .MuiDataGrid-cell:has(.line-delete-cell-btn) {
    padding: 0px;
  }

  .MuiDataGrid-cell:has(.line-delete-cell-btn) {
    @include center-flex;
  }

  .table-select-input-box {
    height: 100;
    width: 100%;
    text-align: center;
    color: #fff;
    cursor: pointer;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .table-date-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    @include table-cell-text-default-format;

    div:nth-child(1),
    input:nth-child(1) {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;
      border: none;

      flex: 1;
      cursor: pointer;
    }

    div:nth-child(1):hover {
      @include table-cell-border-hover;
    }
  }

  .table-edit-date-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    @include table-cell-text-default-format;
  }

  .table-user-icon-box {
    @include center-flex;

    height: 100%;

    .table-user-icon-box-2 {
      @include table-cell-border;

      height: 30px;
      position: relative;
      cursor: pointer;
    }

    .table-user-icon-box-2:hover {
      @include table-cell-border-hover;
    }

    .table-user-icon {
      @include center-flex;

      box-sizing: border-box;

      width: 30px;
      height: 30px;

      border-radius: 30px;
      border: #fff solid 1px;
      background-color: #475467;

      color: #fff;
      font-size: 14px;

      position: absolute;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
  }

  .table-blank-box {
    @include center-flex;
    width: 100%;
    height: 100%;

    .table-blank-border-box {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      gap: 8px;
      cursor: pointer;
    }

    .table-blank-border-box:hover {
      @include table-cell-border-hover;

      opacity: 1;
    }
  }
}
