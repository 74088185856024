.layout-total-body {
  display: flex;
  flex-direction: row;
  height: 100vh;

  .layout-header-body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #ffffff;
    padding: var(--3, 12px) var(--10, 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--White, #fff);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);

    .layout-header-logo {
      display: flex;
      width: 104px;
      height: 58px;
      padding: 7px 4px;
      justify-content: center;
      align-items: center;
    }

    .layout-header-right-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      gap: var(--4, 16px);
      position: relative;

      .layout-header-project-list-selector {
        border-radius: var(--2x, 8px);
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--White, #fff);

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }

      .layout-header-user-icon-box {
        display: flex;
        width: 190px;
        padding: 12px var(--4, 16px);
        box-sizing: border-box;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        border-radius: 100px;
        border: 1px solid var(--Gray-300, #d0d5dd);
        background: var(--White, #fff);

        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        .layout-header-user-icon {
          display: flex;
          padding: 3px;
          align-items: center;
          gap: 4.8px;
          border-radius: 600px;
          background: var(--Gray-300, #d0d5dd);
        }

        .layout-header-user-name {
          overflow: hidden;
          color: var(--Gray-700, #344054);
          text-overflow: ellipsis;

          /* Text md/semibold */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
      }

      .layout-header-dropdown-box {
        position: absolute;
        right: 0;
        top: 60px;
        background-color: #fff;
        z-index: 150;
        width: 190px;
        padding: 4px 0px;
        border-radius: 8px;

        .layout-header-dropdown-item {
          display: block;
          padding: 10px 14px;
          text-decoration: none;
          background-color: #fff;
          color: var(--Gray-700, #344054);
          transition: all 0.3s ease-in-out;
          text-overflow: ellipsis;

          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */

          cursor: pointer;
        }

        .layout-header-dropdown-item:hover {
          background-color: #f9fafb;
        }
      }

      .layout-header-approval-count-body {
        position: absolute;
        top: 0px;
        min-width: 21px;
        box-sizing: border-box;
        display: flex;
        padding: var(--1, 4px);
        justify-content: center;
        align-items: center;
        border-radius: var(--Radius_Circle, 100px);
        background: var(--Error-500, #f04438);

        color: var(--White, #fff);
        text-align: center;

        /* Text xxs(Caption)/Medium */
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 13px; /* 118.182% */
      }
    }
  }

  .sidebar-total-body {
    margin-top: 104px;
    height: calc(100vh - 104px);
    width: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;

    transition: width 0.3s;

    .siderBar-bottom-group {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .siderBar-copy-right-group {
      color: var(--Gray-400, #98a2b3);
      text-align: center;
      font-variant-numeric: lining-nums tabular-nums;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      padding: 20px 0px;
    }

    .siderBar-control-btn-group {
      display: flex;
      justify-content: space-between;
    }

    .siderBar-copy-right-group.none,
    .siderbar-menu-list-control-btn.none {
      display: none;
    }

    .side-bar-menu-container {
      display: flex;
      flex-direction: row-reverse;
      width: 100%;
    }

    .side-bar-menu-text {
      width: 100%;
      word-break: break-word;
      color: var(--Gray-700, #344054);
      /* Text md/medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      transition: all 0.1s ease-in-out;
    }

    .side-bar-menu-text.expandable-active,
    .side-bar-menu-text:hover {
      color: var(--Brand-600, #0080db);
    }

    .side-bar-menu-text.selected-active {
      color: var(--Brand-600, #0080db);
      font-size: 16px;
    }
  }

  .sidebar-total-body.none {
    width: 64px;
  }

  .layout-main-body {
    flex: 1;
    margin-top: 104px;
    box-sizing: border-box;
    transition: margin-left 0.3s margin-top 0.3s;
    padding: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
  }

  .layout-main-body.active {
    margin-left: 18rem;
  }
}
