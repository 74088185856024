.signature-body {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: var(--8, 32px);
  flex: 1 0 0;
  align-self: stretch;

  .signature-contents-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;

    .signture-img {
      // width: 180px;
      max-width: 500px;
      height: 180px;
      max-height: 180px;
      object-fit: contain;
    }
  }
}
