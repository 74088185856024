@import "../../styles/variables.scss";

.qty-control-data-consolidation-modal-total-body {
  @include position-fixed-centor;

  box-sizing: border-box;
  display: flex;
  width: 840px;
  padding: var(--None, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--None, 0px);

  border: 1px solid var(--Gray-200, #e4e7ec);
  background: var(--White, #fff);

  /* popover shadow */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.14),
    0px 0px 2px 0px rgba(0, 0, 0, 0.12);

  background-color: #fff;

  .qty-control-data-consolidation-modal-header-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    align-items: center;
    gap: var(--None, 0px);
    align-self: stretch;
    background: var(--White, #fff);
    height: 70px;
    box-sizing: border-box;
    border-bottom: 1px solid #e4e7ec;

    .qty-control-data-consolidation-modal-header-title {
      display: flex;
      padding: var(--None, 0px);
      align-items: center;
      gap: var(--None, 0px);
      flex: 1 0 0;

      color: var(--Gray-700, #344054);

      /* Text md/bold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
  }

  .qty-control-data-consolidation-modal-main-box {
    display: flex;
    padding: var(--4, 16px) var(--8, 32px);
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
  }

  .qty-control-data-consolidation-modal-btn-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    height: 76px;
    box-sizing: border-box;
    border-top: 1px solid #e4e7ec;

    .qty-control-data-consolidation-modal-cancel-btn,
    .qty-control-data-consolidation-modal-update-btn {
      box-sizing: border-box;
      padding: 10px 16px;

      font-size: 14;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: none;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      cursor: pointer;
    }

    .qty-control-data-consolidation-modal-update-btn {
      border: 1px solid #0080db;
      color: #fff;
      background: #0080db;
    }

    .qty-control-data-consolidation-modal-update-btn:disabled,
    .qty-control-data-consolidation-modal-cancel-btn:disabled {
      border: 1px solid var(--Gray-50, #f9fafb);
      background: var(--Gray-50, #f9fafb);
      color: var(--Gray-300, #d0d5dd);
      cursor: not-allowed; /* 비활성화 커서 */
    }

    // "#344054", "#fff", "#D0D5DD"
    .qty-control-data-consolidation-modal-cancel-btn {
      border: 1px solid #d0d5dd;
      color: #344054;
      background: #fff;
    }
  }
}
