.my-info-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  height: 100%;

  .my-info-top-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--2, 8px);
    align-self: stretch;
  }

  .my-info-bottom-body {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    .my-info-bottom-left-body {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      align-self: stretch;
    }

    .my-info-bottom-right-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--2, 8px);
      flex: 1 0 0;
    }
  }

  .my-info-title-box {
    display: flex;
    height: 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;
    width: 100%;
  }

  .my-info-contents-title {
    color: var(--Gray-500, #667085);

    /* Text lg/medium */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
  }

  .my-info-contents-box2 {
    display: flex;
    padding: var(--5, 20px) 30px;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;
    border-radius: var(--2, 8px);
    background: var(--Gray-50, #f9fafb);

    .my-info-contents-detail-box {
      width: calc(50% - 31px);
      display: flex;
      flex-direction: column;
      gap: 20px;

      .my-info-contents-detail {
        display: flex;
        gap: 30px;
        .my-info-contents-detail-title-box {
          width: 200px;
          box-sizing: border-box;
          display: flex;
          padding: var(--1, 4px);
          // align-items: center;
          gap: var(--2, 8px);
          .my-info-contents-detail-title {
            color: var(--Gray-500, #667085);

            /* Text lg/medium */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
          }
        }

        .my-info-contents-detail-value-box {
          display: flex;
          flex-direction: column;
          // align-items: center;
          gap: var(--2, 8px);
          align-self: stretch;
        }

        .my-info-contents-detail-values-boxs {
          display: flex;
          gap: 8px;
          flex-wrap: wrap;

          .my-info-contents-detail-values-title-box {
            padding: var(--1, 4px);
            width: 150px;
            .my-info-contents-detail-values-title {
              color: var(--Gray-500, #667085);

              /* Text lg/medium */
              font-family: Inter;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px; /* 155.556% */
            }
          }
        }

        .my-info-contents-detail-value {
          word-break: break-all;
          padding: var(--1, 4px);
          color: var(--Gray-700, #344054);
          /* Text lg/medium */
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }
      }
    }
  }

  .my-info-contents-box {
    padding: 20px 30px;
    border-radius: var(--2, 8px);
    background: var(--Gray-50, #f9fafb);
    width: 100%;
    box-sizing: border-box;

    display: flex;

    align-items: flex-start;
    gap: var(--15, 60px);
    align-self: stretch;

    .my-info-contents-detail-left-box {
      display: flex;
      width: 200px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    .my-info-contents-left-title-box {
      display: flex;
      padding: var(--1, 4px);
      align-items: center;
      gap: var(--2, 8px);
    }

    .my-info-contents-left-title {
      color: var(--Gray-500, #667085);

      /* Text lg/medium */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }

    .my-info-contents-left-title.padding {
      display: flex;
      padding: var(--1, 4px);
      align-items: center;
      gap: var(--2, 8px);
    }

    .my-info-contents-detail-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      flex: 1 1 0;
    }
    .my-info-contents-right-title-box {
      display: flex;
      padding: var(--1, 4px);
      align-items: center;
      gap: var(--2, 8px);
      width: 100%;
    }

    .my-info-contents-right-title {
      color: var(--Gray-700, #344054);

      /* Text lg/medium */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
    }

    .my-info-contents-right-title-box2 {
      display: flex;
      align-items: center;
      gap: var(--10, 40px);

      .my-info-contents-right-title-group {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

.my-info-modal-body {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  align-self: stretch;
}

.myinfo-payroll-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  height: 100%;

  .btn-vertival-line {
    height: 28px;
    width: 0px;
    border-right: 2px solid var(--Gray-200, #e4e7ec);
  }

  .myinfo-payroll-contents-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--2, 8px);
    align-self: stretch;

    .myinfo-payroll-contents-title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      height: 40px;

      .myinfo-payroll-contents-title {
        overflow: hidden;
        color: var(--Gray-500, #667085);
        text-overflow: ellipsis;

        /* Text lg/medium */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
      }
    }

    .myinfo-payroll-contents-btn-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: var(--4, 16px);
      align-self: stretch;
    }
    .myinfo-payroll-contents-box {
      display: flex;
      padding: 20px 30px;
      align-items: center;
      gap: var(--10, 40px);
      align-self: stretch;
      border-radius: var(--2, 8px);
      background: var(--Gray-50, #f9fafb);

      .myinfo-payroll-contents-detail-box {
        display: flex;
        align-items: center;
        gap: 8px;

        .myinfo-payroll-contents-detail-title {
          display: flex;
          width: 160px;
          padding: var(--1, 4px);
          align-items: center;
          gap: var(--2, 8px);

          color: var(--Gray-500, #667085);

          /* Text lg/medium */
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }

        .myinfo-payroll-contents-detail-title.contents {
          color: var(--Gray-700, #344054);
        }
      }
    }

    .myinfo-payroll-table-box {
      // flex: 1 0 0;
      height: 100%;
      width: 100%;
    }
  }
}
