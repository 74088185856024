.org-chart-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--1, 4px);
  flex: 1 0 0;
  align-self: stretch;

  .org-chart-card-name-box {
    display: flex;
    align-items: center;
    gap: var(--2, 8px);
    align-self: stretch;

    .org-chart-card-name {
      color: var(--Gray-700, #344054);

      /* Text md/semibold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    .org-chart-card-job {
      color: var(--Gray-700, #344054);

      /* Text sm/regular */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .org-chart-card-info-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    .org-chart-card-info-text {
      color: var(--Gray-400, #98a2b3);

      /* Text xs/medium */
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px; /* 150% */
    }
  }
}

.org-chart-group-title {
  align-self: stretch;

  color: var(--Gray-700, #344054);

  /* Text xs/bold */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
}

.org-chart-group-subtitle {
  color: var(--Gray-700, #344054);
  /* Text sm/medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
