.locale-setting-total-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--7, 28px);
  flex: 1 0 0;
  width: 100%;

  .locale-setting-title {
    align-self: stretch;
    color: var(--Gray-700, #344054);

    /* Text md/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .locale-setting-select-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 16px);
    align-self: stretch;

    width: 100%;
  }
}
