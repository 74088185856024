.login-page-container-body {
  display: flex;
  width: calc(100%);
  height: calc(100vh);
  // padding: 80px 100px;
  margin: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  .login-page-container-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--10, 40px);

    .login-page-container-top-logo img {
      display: flex;
      width: 168px;
      height: 120px;
      padding: 7.5px 0px;
      justify-content: center;
      align-items: center;
    }

    .login-page-container-top-contents-box {
      display: flex;
      width: calc(700px - 144px);
      padding: var(--14, 56px) 72px var(--16, 64px) 72px;
      flex-direction: column;
      align-items: center;
      gap: var(--10, 40px);

      border-radius: 16px;
      background: var(--White, #fff);
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
    }
  }

  .login-page-container-bottom {
    display: flex;
    padding: var(--5, 20px) 0px;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 8px);
    align-self: stretch;

    .login-page-container-bottom-contents {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 20px;

      color: var(--Gray-400, #98a2b3);
      text-align: center;
      font-variant-numeric: lining-nums tabular-nums;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

      .login-page-container-bottom-contents-stick {
        width: 2px;
        align-self: stretch;
        background: var(--Gray-200, #e4e7ec);
      }
    }
  }

  .login-page-title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--1, 4px);
    align-self: stretch;

    .login-page-title {
      color: var(--Gray-600, #475467);
      text-align: center;

      /* Display xs/semibold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }

    .login-page-sub-title {
      color: var(--Gray-500, #667085);
      text-align: center;

      /* Text md/regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
}
