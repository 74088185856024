.includes-link-text-box {
  display: flex;
  flex-direction: row;
  gap: 4px;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  .normal-text {
    color: var(--Gray-400, #98a2b3);
  }

  .under-line-text {
    color: var(--Brand-600, #0080db);
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.MuiDataGrid-pinnedRows {
  .MuiDataGrid-cell {
    border-left: none;
    border-right: none;
  }
}
