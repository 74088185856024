.job-level-setting-modal-contents-body {
  // box-sizing: border-box;
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  width: 792px;
  flex-direction: column;
  align-items: flex-start;
  // gap: var(--5, 20px);
  align-self: stretch;

  .job-level-setting-modal-control-menu {
    display: flex;
    height: 56px;
    padding: var(--1, 4px) var(--2, 8px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .job-level-setting-modal-control-menu-total-box {
      display: flex;
      align-items: center;
      gap: var(--1, 4px);
      .job-level-setting-modal-control-menu-title {
        color: var(--Gray-700, #344054);

        /* Text md/semibold */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
      }
    }
    .job-level-setting-modal-control-menu-btn-box {
      display: flex;
      align-items: center;
      gap: var(--2, 8px);
    }
  }

  .job-level-setting-modal-contents-box {
    display: flex;
    padding: var(--2, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--2, 8px);

    width: 100%;
    box-sizing: border-box;
    height: 300px;
    overflow: auto;
  }
}

.job-level-setting-modal-btn-box {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  height: 76px;
  box-sizing: border-box;
  border-top: 1px solid #e4e7ec;

  .job-level-setting-modal-btn-box2 {
    display: flex;
    gap: 8px;
  }

  .job-level-setting-modal-cancel-btn,
  .job-level-setting-modal-update-btn {
    box-sizing: border-box;
    padding: 10px 16px;

    font-size: 14;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }

  .job-level-setting-modal-update-btn {
    border: 1px solid #0080db;
    color: #fff;
    background: #0080db;
  }

  .job-level-setting-modal-update-btn:disabled,
  .job-level-setting-modal-cancel-btn:disabled {
    border: 1px solid var(--Gray-50, #f9fafb);
    background: var(--Gray-50, #f9fafb);
    color: var(--Gray-300, #d0d5dd);
    cursor: not-allowed; /* 비활성화 커서 */
  }

  // "#344054", "#fff", "#D0D5DD"
  .job-level-setting-modal-cancel-btn {
    border: 1px solid #d0d5dd;
    color: #344054;
    background: #fff;
  }
}

.job-level-setting-modal-control-menu-btn {
  display: flex;
  padding: var(--2, 8px);
  align-items: center;
  gap: 8px;

  .job-level-setting-modal-control-menu-btn-text {
    color: var(--Gray-700, #344054);

    /* Text sm/medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}
