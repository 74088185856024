@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin table-cell-height {
  height: 20px;
  line-height: 20px;
  padding: 5px;
}

@mixin table-cell-border {
  border: rgba($color: #000000, $alpha: 0) solid 1px;
  transition: all ease-out 0.1s;
}

@mixin table-cell-border-hover {
  border: #667085 solid 1px;
  border-radius: 5px;
}

@mixin table-cell-text-default-format {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

@mixin position-fixed-centor {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
