.security-setting-total-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--8, 32px);
  flex: 1 0 0;
  align-self: stretch;
  .security-setting-input-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 16px);
    align-self: stretch;
  }

  .security-setting-input-bottom {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
}
