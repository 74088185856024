.cost-code-table-body {
  height: 100%;
  width: 100%;
}

.code-add-modal-contents-body {
  flex: 1 0 0;
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  align-self: stretch;

  .code-add-modal-contents-box {
    display: flex;
    padding: var(--4, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 16px);
    align-self: stretch;
    border-radius: var(--1, 4px);
    background: var(--Gray-50, #f9fafb);
    .code-add-modal-input-box {
      display: flex;
      align-items: flex-end;
      gap: var(--4, 16px);
      align-self: stretch;
    }
  }
}
