.annual-leave-line-body {
  padding: 20px;
  height: 100%;
  // width: 100%;
  box-sizing: border-box;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  .annual-leave-line-box {
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);
    height: calc(33% - 20px);

    gap: 16px;

    .annual-leave-line-title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      display: flex;
      align-items: center;
      gap: var(--2, 8px);
      align-self: stretch;
      height: 38px;

      .annual-leave-line-title {
        overflow: hidden;
        color: var(--Gray-700, #344054);
        text-overflow: ellipsis;

        /* Text lg/semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
      }
      // .annual-leave-line-btn-box {
      // }
    }

    .annual-leave-line-table-box {
      height: calc(100% - 52px);
    }
  }
}
