.big-calendar-toolbar-total-body {
  display: flex;
  padding: var(--1, 4px) 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 32px;

  .big-calendar-toolbar-nav-btn-box {
    display: flex;

    align-items: center;
    gap: var(--4, 16px);

    .big-calendar-toolbar-nav-detail-btn-box {
      display: flex;
      align-items: flex-start;
      .big-calendar-toolbar-nav-detail-btn {
        padding: 8px;
      }

      .big-calendar-toolbar-nav-detail-btn:first-child {
        border-radius: var(--2, 8px) var(--None, 0px) var(--None, 0px)
          var(--2, 8px);
      }

      .big-calendar-toolbar-nav-detail-btn:last-child {
        border-radius: var(--None, 0px) 8px 8px var(--None, 0px);
      }
    }
    .big-calendar-toolbar-nav-today-btn-box {
      .big-calendar-toolbar-nav-today-btn {
        text-transform: none;
        display: flex;
        padding: 10px 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;

        border-radius: 8px;
      }
    }
  }

  .big-calendar-toolbar-label-box {
    .big-calendar-toolbar-label {
      color: var(--Gray-700, #344054);
      text-align: center;

      /* Text xl/bold */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 150% */
    }
  }

  .big-calendar-toolbar-view-btn-box {
    display: flex;
    align-items: flex-start;
  }

  .big-calendar-toolbar-btn {
    text-transform: none;
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    transition: all 0.3s ease-in-out;
  }

  .big-calendar-toolbar-btn.active {
    background-color: #f4feff;
  }

  .big-calendar-toolbar-btn:first-child {
    border-radius: var(--2, 8px) var(--None, 0px) var(--None, 0px) var(--2, 8px);
  }

  .big-calendar-toolbar-btn:last-child {
    border-radius: var(--None, 0px) 8px 8px var(--None, 0px);
  }

  .big-calendar-toolbar-btn-label {
    color: var(--Brand-600, #0080db);
    /* Text md/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
}

.big-calendar-event-text {
  color: var(--Netural-White, #fff);
  /* Text md/semibold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.big-calendar-header-body {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  // height: 40px;
  padding: var(--1, 4px) var(--3, 12px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  background: var(--Gray-50, #f9fafb);

  .big-calendar-header-label {
    color: var(--Gray-900, #101828);

    /* Text lg/semibold */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }
}

.big-calendar-date-header-body {
  padding: var(--2, 8px) var(--3, 12px);
  padding-bottom: 0px;

  .big-calendar-date-header-label {
    color: var(--Gray-900, #101828);
    text-align: right;
    /* Text lg/medium */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
  }

  .legal-holiday {
    color: red;
  }

  .another-month {
    color: var(--Gray-300, #d0d5dd);
  }
}

.rbc-button-link {
  width: 100%;
  height: 100%;
}

.rbc-header {
  padding: 0;
  // height: 40px;
}

.rbc-off-range-bg {
  background-color: white !important; /* 원하는 색상으로 변경 */
}

.hr-calendar-using-modal-contents-body {
  box-sizing: border-box;
  width: 100%;
  padding: var(--4, 16px) var(--6, 24px);
  height: 100%;
}

.hr-calendar-using-modal-btn-body {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  align-self: stretch;

  border-top: 1px solid #e4e7ec;

  .hr-calendar-using-modal-cancel-btn,
  .hr-calendar-using-modal-update-btn {
    box-sizing: border-box;
    padding: 10px 16px;

    font-size: 14;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }

  .hr-calendar-using-modal-update-btn {
    border: 1px solid #0080db;
    color: #fff;
    background: #0080db;
  }

  .hr-calendar-using-modal-update-btn:hover {
    background: #0080db;
  }

  .hr-calendar-using-modal-cancel-btn:hover {
    border: 1px solid #d0d5dd;
  }

  .hr-calendar-using-modal-update-btn:disabled,
  .hr-calendar-using-modal-cancel-btn:disabled {
    border: 1px solid var(--Gray-50, #f9fafb);
    background: var(--Gray-50, #f9fafb);
    color: var(--Gray-300, #d0d5dd);
    cursor: not-allowed; /* 비활성화 커서 */
  }

  // "#344054", "#fff", "#D0D5DD"
  .hr-calendar-using-modal-cancel-btn {
    border: 1px solid #d0d5dd;
    color: #344054;
    background: #fff;
  }
}

.using-menu-body {
  display: flex;
  padding: var(--4, 16px) var(--3, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--4, 16px);
  align-self: stretch;
  height: 360px;
  box-sizing: border-box;
  border-radius: 8px;
  background: var(--Gray-50, #f9fafb);
  overflow: auto;

  .using-menu-column-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--2, 8px);
    align-self: stretch;
  }
  .using-menu-flex-box {
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }

  .using-menu-flex-1-box {
    display: flex;
    padding: var(--1, 4px) var(--2, 8px);
    align-items: center;
    gap: 10px;
    // flex: 1 0 0;
    width: 100%;
    box-sizing: border-box;
  }

  .using-menu-flex-space-between-box {
    display: flex;
    height: 48px;
    padding: var(--1, 4px) var(--2, 8px);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .using-menu-date-range-box {
    display: flex;
    align-items: flex-start;
    gap: var(--4, 16px);
    align-self: stretch;

    .using-menu-date-range {
      display: flex;
      padding: var(--1, 4px) var(--2, 8px);
      align-items: center;
      gap: var(--2, 8px);
      flex: 1 0 0;
    }
  }

  .using-menu-default-text {
    color: var(--Gray-700, #344054);

    /* Text md/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .using-menu-work-list-card {
    display: flex;
    padding: var(--1, 4px) var(--2, 8px);
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    .using-menu-work-list-range-box {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: var(--4, 16px);
    }
  }

  .using-menu-work-list-card.align-center {
    align-items: center;
  }
}

.approval-line-btn-body {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  height: 48px;
  padding: var(--1, 4px) var(--2, 8px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  .approval-line-btn-label {
    color: var(--Gray-700, #344054);

    /* Text md/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .approval-line-btn {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: var(--2, 8px);
    cursor: pointer;
  }
}

.approval-line-modal-contents-body {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  align-self: stretch;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .approval-line-select-btn-box {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: var(--4, 16px);
    align-self: stretch;

    .approval-line-select-body {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
    }

    .approval-line-select-btn {
      cursor: pointer;
      width: 80px;
      display: flex;
      padding: var(--2, 8px);
      justify-content: center;
      align-items: center;
      gap: 8px;

      border-radius: var(--2, 8px);
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);
    }
  }

  .approval-line-select-user-body {
    display: flex;
    align-items: flex-start;
    gap: var(--4, 16px);
    flex: 1 0 0;
    align-self: stretch;

    .approval-line-selected-user-body {
      flex: 1 0 0;
      height: 100%;

      .approval-line-selected-user-title {
        display: flex;
        padding: var(--2, 8px) 0px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        color: var(--Gray-700, #344054);

        /* Text lg/semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px; /* 155.556% */
      }

      .approval-line-selected-user-box {
        display: flex;
        padding: var(--1, 4px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--2, 8px);
        flex: 1 0 0;
        align-self: stretch;
        border-radius: var(--1, 4px);
        background: var(--Gray-50, #f9fafb);
        height: 240px;
        box-sizing: border-box;
        overflow-y: auto;

        .user-card-body {
          display: flex;
          padding: var(--3, 12px) var(--5, 20px);
          align-items: center;
          gap: 10px;
          align-self: stretch;

          .user-card-inform-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex: 1 0 0;
            .user-card-inform-name {
              color: var(--Gray-700, #344054);

              /* Text sm/medium */
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */
            }
            .user-card-inform-job-position {
              color: var(--Gray-500, #667085);

              /* Text sm/regular */
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 142.857% */
            }
          }

          .user-card-btn-box {
            display: flex;
            align-items: center;
            gap: var(--2, 8px);
            cursor: pointer;

            .user-card-btn {
              display: flex;
              padding: var(--2, 8px);
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 1 0 0;
              align-self: stretch;
            }

            .user-card-btn.rotate {
              rotate: 180deg;
            }
          }
        }
      }
    }
  }
}
