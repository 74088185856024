.job-setting-card-body {
  width: 100%;
  display: flex;
  height: 52px;
  padding: var(--2, 8px);
  align-items: center;
  justify-content: space-between;
  gap: var(--2, 8px);
  align-self: stretch;
  border-radius: var(--1, 4px);
  box-sizing: border-box;

  .job-setting-card-name-box {
    display: flex;
    align-items: center;
    flex: 1 0 0;

    .job-setting-card-name {
      display: flex;
      padding: var(--1, 4px) var(--3, 12px);
      align-items: center;
      gap: var(--2, 8px);
      align-self: stretch;
      flex: 1 0 0;
      border-radius: 8px;

      color: var(--Gray-700, #344054);
      /* Text md/medium */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .job-setting-card-name.input {
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--Netural-White, #fff);
    }
  }
  .job-setting-card-btn-box {
    display: flex;
    flex-direction: row;
    gap: var(--2, 8px);
  }
}

.job-setting-card-body.focus {
  background: var(--Brand-50, #f4feff);
}
