.admin-user-create-total-body {
  display: flex;
  padding: var(--5, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 16px;
  background: var(--White, #fff);

  .admin-user-create-title-box {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 36px;

    .admin-user-create-title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;

      overflow: hidden;
      color: var(--Gray-700, #344054);
      text-overflow: ellipsis;

      /* Display xs/semibold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }

    .admin-user-create-btn {
      display: flex;
      padding: var(--1, 4px);
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);

      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }

  .admin-user-create-table-box {
    width: 100%;
    flex: 1;
  }
}

.create-user-modal-total-body {
  .create-user-modal-add-input-box {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--7, 28px);
    align-self: stretch;

    .create-user-modal-contents-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      width: 100%;
      .create-user-modal-contents-title {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        line-height: 20px;
        font-family: Inter;
        color: var(--Gray-700, #344054);
      }
    }
  }

  .create-user-modal-control-box {
    display: flex;
    padding: var(--4, 16px) var(--6, 24px);
    justify-content: center;
    gap: 8px;
    align-items: center;
    align-self: stretch;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .create-user-modal-submit-btn,
    .create-user-modal-cancle-btn {
      box-sizing: border-box;
      padding: 10px 16px;
      width: 110px;
      font-size: 14;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-transform: none;
      border-radius: 8px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      cursor: pointer;
    }

    // "#fff", "#0080DB", "#0080DB"
    .create-user-modal-submit-btn {
      border: 1px solid #0080db;
      color: #fff;
      background: #0080db;
    }

    .create-user-modal-submit-btn:disabled,
    .create-user-modal-cancle-btn:disabled {
      border: 1px solid var(--Gray-50, #f9fafb);
      background: var(--Gray-50, #f9fafb);
      color: var(--Gray-300, #d0d5dd);
      cursor: not-allowed; /* 비활성화 커서 */
    }

    // "#344054", "#fff", "#D0D5DD"
    .create-user-modal-cancle-btn {
      border: 1px solid #d0d5dd;
      color: #344054;
      background: #fff;
    }
  }
}

.tax-report-total-body {
  display: flex;
  padding: var(--5, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  flex: 1 0;
  align-self: stretch;
  border-radius: 16px;
  background: var(--White, #fff);

  .tax-report-total-top-box {
    display: flex;
    height: 52px;
    padding: var(--2, 8px) 0px;
    align-items: center;
    gap: var(--2, 8px);
    flex-shrink: 0;
    align-self: stretch;
    box-sizing: border-box;

    .tax-report-total-title {
      overflow: hidden;
      color: var(--Gray-700, #344054);
      text-overflow: ellipsis;

      /* Display xs/semibold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }
  }

  .tax-report-total-bottom-box {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    gap: var(--5, 20px);
    overflow-y: auto;

    .tax-report-selction-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--5, 20px);
      width: 100%;
      box-sizing: border-box;

      .tax-report-selction-box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .tax-report-selction-title {
          box-sizing: border-box;
          padding: var(--2, 8px);

          color: var(--Gray-700, #344054);
          /* Text lg/bold */
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px; /* 155.556% */
        }

        .tax-report-selction-contents {
          display: flex;

          padding: var(--5, 20px) var(--8, 32px);
          align-items: center;
          gap: var(--8, 32px);
          align-self: stretch;
          border-radius: var(--1, 4px);
          background: var(--Gray-50, #f9fafb);
          width: 100%;
          box-sizing: border-box;
          flex-direction: row;
          flex-wrap: wrap;

          .tax-report-selction-content {
            display: flex;
            padding: 10px var(--4, 16px);
            align-items: center;
            gap: 12px;
            align-self: stretch;

            .tax-report-selction-content-name {
              color: var(--Gray-700, #344054);
              /* Text sm/Medium */
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px; /* 142.857% */

              cursor: pointer;
            }
          }
        }

        .tax-report-selction-contents.column {
          flex-direction: column;
          align-items: flex-start;
          gap: var(--8, 32px);

          .tax-report-selction-contents-detail {
            display: flex;
            align-items: center;
            gap: var(--8, 32px);

            .months-picker-box {
              display: flex;
              flex-direction: column;
              gap: 6px;

              .months-picker-label {
                font-size: 14px;
                font-weight: 600;
                font-style: normal;
                line-height: 20px;
                font-family: Inter;
                color: var(--Gray-700, #344054);
              }
            }

            .tax-report-selction-content {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
            }
          }
        }
      }
    }

    .tax-report-btn-body {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .tax-report-btn {
        box-sizing: border-box;
        padding: 10px 16px;

        font-size: 14;
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-transform: none;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border: 1px solid #0080db;
        color: #fff;
        background: #0080db;

        cursor: pointer;
      }

      .tax-report-btn:disabled {
        border: 1px solid var(--Gray-50, #f9fafb);
        background: var(--Gray-50, #f9fafb);
        color: var(--Gray-300, #d0d5dd);
        cursor: not-allowed; /* 비활성화 커서 */
      }
    }
  }
}

.work-policy-setting-page-body {
  display: flex;
  // width: 1580px;
  height: 100%;
  padding: var(--5, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  border-radius: var(--4, 16px);
  background: var(--Netural-White, #fff);

  .work-policy-setting-page-title-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .work-policy-setting-page-btn-box {
    display: flex;
    align-items: center;
    gap: var(--4, 16px);

    .vertical-line {
      width: 0;
      height: 28px;
      border-right: 2px solid var(--Gray-200, #e4e7ec);
    }
  }

  .work-policy-setting-page-title {
    display: flex;
    height: 36px;
    padding: var(--2, 8px) 0px;
    align-items: center;
    gap: var(--2, 8px);
    flex-shrink: 0;
    align-self: stretch;

    overflow: hidden;
    color: var(--Gray-700, #344054);
    text-overflow: ellipsis;

    /* Display xs/semibold */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
  }
}

.dayoff-policy-setting-page-body {
  display: flex;
  height: 100%;
  padding: var(--5, 20px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--8, 32px);
  border-radius: var(--4, 16px);
  background: var(--Netural-White, #fff);
  overflow: auto;

  .dayoff-policy-setting-page-contents-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }

  .dayoff-policy-setting-page-title-box {
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    .dayoff-policy-setting-page-title {
      display: flex;
      // box-sizing: border-box;
      height: 36px;
      padding: var(--2, 8px) 0px;
      align-items: center;
      gap: var(--2, 8px);
      flex: 1 0 0;

      overflow: hidden;
      color: var(--Gray-700, #344054);
      text-overflow: ellipsis;

      /* Display xs/semibold */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
    }
    .dayoff-policy-setting-page-add-btn {
      display: flex;
      padding: 10px var(--4, 16px);
      justify-content: center;
      align-items: center;
      gap: 8px;
      // flex: 1 0 0;

      border-radius: var(--2, 8px);
      border: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);

      /* Shadow/xs */
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      text-transform: none;

      .dayoff-policy-setting-page-add-btn-label {
        color: var(--Gray-400, #98a2b3);

        /* Text sm/medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.company-setting-contents-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  height: 100%;

  .company-setting-contents-detail-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--2, 8px);
    align-self: stretch;
    overflow-y: hidden;

    .company-setting-contents-detail-title-box {
      display: flex;
      height: 40px;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      align-self: stretch;
    }
  }

  .company-setting-contents-detail-body:nth-child(2) {
    flex: 1;
  }

  .company-setting-contents-detail-top-body {
    display: flex;
    padding: var(--5, 20px) 30px;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    width: 100%;
    box-sizing: border-box;
    border-radius: var(--2, 8px);
    background: var(--Gray-50, #f9fafb);

    .company-setting-contents-detail-top-box {
      width: calc(50% - 31px);
      display: flex;
      flex-direction: column;
      gap: 20px;

      .company-setting-contents-detail-box {
        display: flex;
        gap: 30px;

        .company-setting-contents-title-box {
          width: 300px;
          box-sizing: border-box;
          display: flex;
          padding: var(--1, 4px);
          align-items: center;
          gap: var(--2, 8px);
          .company-setting-contents-title {
            color: var(--Gray-500, #667085);

            /* Text lg/medium */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
          }
        }
        .company-setting-contents-value-box {
          display: flex;
          padding: var(--1, 4px);
          align-items: center;
          gap: var(--2, 8px);
          align-self: stretch;
          flex: 1;

          .company-setting-contents-value {
            color: var(--Gray-700, #344054);
            word-break: break-all;

            /* Text lg/medium */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
          }
        }
      }
    }
  }

  .company-setting-contents-detail-bottom-body {
    display: flex;
    padding: var(--5, 20px) 30px;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    align-self: stretch;
    border-radius: var(--2, 8px);
    background: var(--Gray-50, #f9fafb);

    .detail-bottom-detail-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--2, 8px);
      width: calc(50% - 20px);
      box-sizing: border-box;
      // height: 100%;
      // height: calc(100% - 8px - 28px);

      .detail-bottom-detail-title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .company-setting-contents-detail-title-btn-box {
          display: flex;
          align-items: center;
          gap: var(--4, 16px);
        }

        .contents-detail-btn-box2 {
          display: flex;
          align-items: center;
          gap: var(--2, 8px);
        }

        .verticial-line {
          height: 24px;
          border-right: 2px solid var(--Gray-200, #e4e7ec);
        }
      }
    }
  }

  .company-setting-contents-detail-title {
    color: var(--Gray-500, #667085);

    /* Text lg/medium */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
  }
}
