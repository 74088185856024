.company-info-setting-modal-contents-body {
  display: flex;
  padding: var(--8, 32px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  align-self: stretch;

  .company-info-setting-modal-contents-box {
    display: flex;
    padding: var(--4, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--4, 16px);
    align-self: stretch;
    border-radius: 8px;
    background: var(--Gray-50, #f9fafb);

    .company-info-setting-modal-contents-half {
      display: flex;
      align-items: flex-end;
      gap: 16px;
      align-self: stretch;
    }
  }
}

.company-info-setting-modal-btn-box {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  height: 76px;
  box-sizing: border-box;
  border-top: 1px solid #e4e7ec;

  .company-info-setting-modal-cancel-btn,
  .company-info-setting-modal-update-btn {
    box-sizing: border-box;
    padding: 10px 16px;

    font-size: 14;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: none;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }

  .company-info-setting-modal-update-btn {
    border: 1px solid #0080db;
    color: #fff;
    background: #0080db;
  }

  .company-info-setting-modal-update-btn:not(:disabled):hover {
    border: 1px solid var(--Brand-700, #0060b7);
    background: var(--Brand-700, #0060b7);
  }

  .company-info-setting-modal-cancel-btn:not(:disabled):hover {
    border: 1px solid var(--Gray-300, #d0d5dd);
    background: var(--Brand-50, #f4feff);
  }

  .company-info-setting-modal-update-btn:disabled,
  .company-info-setting-modal-cancel-btn:disabled {
    border: 1px solid var(--Gray-50, #f9fafb);
    background: var(--Gray-50, #f9fafb);
    color: var(--Gray-300, #d0d5dd);
    cursor: not-allowed; /* 비활성화 커서 */
  }

  // "#344054", "#fff", "#D0D5DD"
  .company-info-setting-modal-cancel-btn {
    border: 1px solid #d0d5dd;
    color: #344054;
    background: #fff;
  }
}
