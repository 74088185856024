// html,
// body,
// div,
// span,
// applet,
// object,
// iframe,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// blockquote,
// pre,
// a,
// abbr,
// acronym,
// address,
// big,
// cite,
// code,
// del,
// dfn,
// em,
// img,
// ins,
// kbd,
// q,
// s,
// samp,
// small,
// strike,
// strong,
// sub,
// sup,
// tt,
// var,
// b,
// u,
// i,
// center,
// dl,
// dt,
// dd,
// ol,
// ul,
// li,
// fieldset,
// form,
// label,
// legend,
// table,
// caption,
// tbody,
// tfoot,
// thead,
// tr,
// th,
// td,
// article,
// aside,
// canvas,
// details,
// embed,
// figure,
// figcaption,
// footer,
// header,
// hgroup,
// menu,
// nav,
// output,
// ruby,
// section,
// summary,
// time,
// mark,
// audio,
// video {
//   margin: 0;
//   padding: 0;
//   border: 0;
//   font-size: 100%;
//   font: inherit;
//   vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article,
// aside,
// details,
// figcaption,
// figure,
// footer,
// header,
// hgroup,
// menu,
// nav,
// section {
//   display: block;
// }
// body {
//   line-height: 1;
// }
// ol,
// ul {
//   list-style: none;
// }
// blockquote,
// q {
//   quotes: none;
// }
// blockquote:before,
// blockquote:after,
// q:before,
// q:after {
//   content: "";
//   content: none;
// }
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

:root {
  /* Color styles */
  --white: rgba(255, 255, 255, 1);
  --black: rgba(0, 0, 0, 1);
  --black-opa45: rgba(0, 0, 0, 0.44999998807907104);
  --gray--900: rgba(16, 24, 40, 1);
  --gray--800: rgba(29, 41, 57, 1);
  --gray--700: rgba(52, 64, 84, 1);
  --gray--600: rgba(71, 84, 103, 1);
  --gray--500: rgba(102, 112, 133, 1);
  --gray--400: rgba(152, 162, 179, 1);
  --gray--300: rgba(208, 213, 221, 1);
  --gray--200: rgba(228, 231, 236, 1);
  --gray--100: rgba(242, 244, 247, 1);
  --gray--50: rgba(249, 250, 251, 1);
  --gray--25: rgba(252, 252, 253, 1);
  --gray--warm-100: rgba(240, 240, 240, 1);
  --brand--900: rgba(0, 48, 122, 1);
  --brand--800: rgba(0, 68, 147, 1);
  --brand--700: rgba(0, 96, 183, 1);
  --brand--600: rgba(0, 128, 219, 1);
  --brand--500: rgba(0, 166, 255, 1);
  --brand--400: rgba(63, 200, 255, 1);
  --brand--300: rgba(102, 220, 255, 1);
  --brand--200: rgba(153, 238, 255, 1);
  --brand--100: rgba(204, 250, 255, 1);
  --brand--75: rgba(232, 253, 255, 1);
  --brand--50: rgba(244, 254, 255, 1);
  --error--900: rgba(122, 39, 26, 1);
  --error--800: rgba(145, 32, 24, 1);
  --error--700: rgba(180, 35, 24, 1);
  --error--600: rgba(217, 45, 32, 1);
  --error--500: rgba(240, 68, 56, 1);
  --error--400: rgba(249, 112, 102, 1);
  --error--300: rgba(253, 162, 155, 1);
  --error--200: rgba(254, 205, 202, 1);
  --error--100: rgba(254, 228, 226, 1);
  --error--50: rgba(254, 243, 242, 1);
  --error--25: rgba(255, 251, 250, 1);
  --sucess--600: rgba(3, 152, 85, 1);
  --sucess--500: rgba(18, 183, 106, 1);
  --warning--500: rgba(247, 144, 9, 1);

  /* Text-size styles */
  --display-2xl--bold: 72px;
  --display-2xl--semibold: 72px;
  --display-2xl--medium: 72px;
  --display-2xl--regular: 72px;
  --display-xl--bold: 60px;
  --display-xl--semibold: 60px;
  --display-xl--medium: 60px;
  --display-xl--regular: 60px;
  --display-lg--bold: 48px;
  --display-lg--semibold: 48px;
  --display-lg--medium: 48px;
  --display-lg--regular: 48px;
  --display-md--bold: 36px;
  --display-md--semibold: 36px;
  --display-md--medium: 36px;
  --display-md--regular: 36px;
  --display-sm--bold: 30px;
  --display-sm--semibold: 30px;
  --display-sm--medium: 30px;
  --display-sm--regular: 30px;
  --display-xs--bold: 24px;
  --display-xs--semibold: 24px;
  --display-xs--medium: 24px;
  --display-xs--regular: 24px;
  --text-xl--bold: 20px;
  --text-xl--semibold: 20px;
  --text-xl--medium: 20px;
  --text-xl--regular: 20px;
  --text-lg--bold: 18px;
  --text-lg--semibold: 18px;
  --text-lg--medium: 18px;
  --text-lg--regular: 18px;
  --text-md--bold: 16px;
  --text-md--semibold: 16px;
  --text-md--medium: 16px;
  --text-md--regular: 16px;
  --text-sm--bold: 14px;
  --text-sm--semibold: 14px;
  --text-sm--medium: 14px;
  --text-sm--regular: 14px;
  --text-xs--bold: 12px;
  --text-xs--semibold: 12px;
  --text-xs--medium: 12px;
  --text-xs--regular: 12px;

  /* Effect styles */
  --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
  --xs-focused-brand: 0px 0px 0px rgba(204, 250, 255, 0.5);
  --input-xs--focus: 0px 0px 0px rgba(204, 250, 255, 0.5),
    0px 1px 2px rgba(16, 24, 40, 0.05);
  --shadow-sm: 0px 1px 2px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  --shadow-md: 0px 2px 4px rgba(16, 24, 40, 0.06),
    0px 4px 8px rgba(16, 24, 40, 0.1);
  --shadow-lg: 0px 4px 6px rgba(16, 24, 40, 0.03),
    0px 12px 16px rgba(16, 24, 40, 0.08);
  --shdow-xl: 0px 8px 8px rgba(16, 24, 40, 0.03),
    0px 20px 24px rgba(16, 24, 40, 0.08);

  /* Size(16px base) */
  /* numbers */
  --1: 0.25rem;
  --2: 0.5rem;
  --3: 0.75rem;
  --4: 1rem;
  --5: 1.25rem;
  --6: 1.5rem;
  --7: 1.75rem;
  --8: 2rem;
  --9: 2.25rem;
  --10: 2.5rem;
  --11: 2.75rem;
  --12: 3rem;
  --13: 3.25rem;
  --14: 3.5rem;
  --15: 3.75rem;
  --16: 4rem;
  --17: 4.25rem;
  --18: 4.5rem;
  --19: 4.75rem;
  --20: 5rem;
  --21: 5.25rem;
  --22: 5.5rem;

  background: var(--screen-BG, #fafafa);
}

body {
  margin: 0;
  padding: 0;

  font-family: Inter;
}

.swal2-container {
  z-index: 999999;
}
