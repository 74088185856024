@import "@styles/variables";

.update-comment-total-body {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  box-shadow: -10px 0 5px -5px rgba(0, 0, 0, 0.1); /* 그림자 설정 */
  z-index: 998;

  .update-comment-drag-bar {
    @include center-flex;
    width: 20px;
    height: 100vh;

    cursor: col-resize;

    svg {
      display: none;
    }

    transition: all ease-in-out 0.5s;
  }

  .update-comment-drag-bar:hover {
    background-color: rgba($color: #000000, $alpha: 0.1);

    svg {
      display: block;
    }
  }

  .update-comment-main-body {
    padding: 20px 20px 20px 0px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    gap: 10px;
    overflow-y: auto;

    .update-comment-title-close {
      width: 24px;
      height: 24px;
    }

    .update-comment-title-box {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .update-comment-title-parent {
        color: var(--Brand-500, var(--blue-01, #00a6ff));

        /* Text sm/regular */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
      .update-comment-title {
        color: var(--Gray-700, #344054);

        /* Text sm/semibold */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

.comment-sheet-total-body {
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .comment-sheet-add-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 180px;
    justify-content: space-between;

    .comment-sheet-markdown-box {
      .ql-toolbar {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }

      .ql-container {
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;

        .ql-editor {
          height: calc(180px - 10px - 37px - 42px);
        }
      }
    }

    .comment-sheet-control-box {
      display: flex;
      justify-content: space-between;
    }
  }
  .re-comment-eidtor-total-body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .re-comment-eidtor-editor-box {
      .ql-container {
        height: 75px;
        border-radius: 10px;
      }
    }

    .re-comment-eidtor-btn-box {
      display: flex;
      justify-content: space-between;

      .re-comment-eidtor-control-btn-box {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
    }
  }

  .re-comment-list-box {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .re-comment-card-total-body {
    display: inline-flex;
    flex-direction: column;
    gap: 10px;

    // width: 60%;

    .re-comment-card-contents-box {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 10px 16px;
      background-color: #f6f7fb;
      border-radius: 10px;

      .re-comment-card-author-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .re-comment-card-author {
          font-weight: 600;
        }
      }
    }
  }

  .re-comment-card-total-body.editor-mode {
    width: 100%;
  }
  .comment-sheet-list-box {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .comment-card-total-body {
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 10px;
      // height: 400px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .comment-horizontal-line {
        border-top: 1px solid #ccc;
      }

      .comment-card-total-author-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .comment-card-total-author {
          color: var(--Gray-900, #101828);
          text-overflow: ellipsis;

          /* Text lg/medium */
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 155.556% */
        }

        .comment-card-total-author-control-box {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .comment-card-attach-list-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
      }

      .recomment-add-button-box {
        border: 1px solid #000000;
      }
    }
  }
}

.attachment-sheet-total-body {
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .attachment-sheet-control-box {
    display: flex;
    justify-content: space-between;
  }
}

.log-table-body {
  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeader {
    border-right: 1px solid #ccc; /* 세로선 색상과 두께 조정 */
  }

  .MuiDataGrid-cell:last-child,
  .MuiDataGrid-columnHeader:last-child {
    border-right: none;
  }

  .table-text-amount-input-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .table-text-amount-symbol {
      width: 20px;
    }

    .table-text-amount-number {
      width: 100%;
      text-align: center;
      @include text-ellipsis;
    }
  }

  .table-text-input-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    @include table-cell-text-default-format;

    .table-text-input-lead-box {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--2, 8px);
      align-self: stretch;
      margin-top: 4px;

      .table-text-input-lead-label {
        align-self: stretch;
        color: var(--Gray-700, #344054);
        text-align: center;

        /* Text sm/medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    div:nth-child(1),
    input:nth-child(1) {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;

      border: none;
      flex: 1;
      cursor: pointer;
    }

    input:nth-child(1) {
      width: 100%;
    }

    div:nth-child(2) {
      // width: 50px;
      @include center-flex;
      cursor: pointer;
    }
  }

  .MuiDataGrid-cell:has(.table-select-input-box),
  .MuiDataGrid-cell:has(.line-delete-cell-btn) {
    padding: 0px;
  }

  .MuiDataGrid-cell:has(.line-delete-cell-btn) {
    @include center-flex;
  }

  .table-select-input-box {
    height: 100;
    width: 100%;
    text-align: center;
    color: #fff;
    cursor: pointer;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .table-date-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    @include table-cell-text-default-format;

    div:nth-child(1),
    input:nth-child(1) {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;
      border: none;

      flex: 1;
      cursor: pointer;
    }
  }

  .table-edit-date-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;

    @include table-cell-text-default-format;
  }

  .table-user-icon-box {
    @include center-flex;

    height: 100%;

    .table-user-icon-box-2 {
      @include table-cell-border;

      height: 30px;
      position: relative;
      cursor: pointer;
    }

    .table-user-icon {
      @include center-flex;

      box-sizing: border-box;

      width: 30px;
      height: 30px;

      border-radius: 30px;
      border: #fff solid 1px;
      background-color: #475467;

      color: #fff;
      font-size: 14px;

      position: absolute;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
  }

  .table-blank-box {
    @include center-flex;
    width: 100%;
    height: 100%;

    .table-blank-border-box {
      @include text-ellipsis;
      @include table-cell-border;
      @include table-cell-height;

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      gap: 8px;
      cursor: pointer;
    }
  }
}
