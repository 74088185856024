.prm-body {
  display: flex;
  flex-direction: column;
  gap: var(--8, 32px);
  width: 100%;
  height: 100%;

  .prm-btn-body {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .prm-btn-box {
      display: flex;
      align-items: center;
      gap: var(--4, 16px);
    }
  }

  .prm-btn-vertical-line {
    height: 28px;
    border-right: 2px solid var(--Gray-200, #e4e7ec);
  }

  .prm-table-body {
    height: 100%;
  }
}

.payroll-detail-modal-contents-body {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  align-self: stretch;
  flex: 1 0 0;

  .payroll-detail-modal-contents-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 12px);
    align-self: stretch;
    height: 100%;
  }

  .payroll-detail-modal-title-box {
    width: 100%;
    display: flex;
    padding: var(--1, 4px) 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .payroll-detail-modal-title {
      color: var(--Gray-700, #344054);

      /* Text lg/semibold */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 155.556% */
    }

    .payroll-detail-modal-title-btn-box {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: var(--2, 8px);
      align-self: stretch;
    }
  }

  .payroll-detail-modal-table-box {
    width: 100%;
    flex: 1 0 0;
  }

  .payroll-detail-modal-result-box {
    display: flex;
    padding: var(--1, 4px) 0px;
    align-items: center;
    gap: var(--1, 4px);
    align-self: stretch;
    .payroll-detail-modal-result {
      color: var(--Gray-700, #344054);

      /* Text md/medium */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .payroll-detail-modal-result.blue {
      color: var(--Brand-600, #0080db);
    }
  }
}

.payroll-import-modal-main-box {
  display: flex;
  padding: var(--4, 16px) var(--6, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--5, 20px);
  align-self: stretch;
  flex: 1 0 0;

  .payroll-import-modal-temp-down-box {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .payroll-import-modal-temp-title-box {
      display: flex;
      align-items: center;
      gap: var(--2, 8px);
      .payroll-import-modal-temp-title {
        color: var(--Gray-700, #344054);

        /* Text sm/semibold */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
  }

  .payroll-import-info-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    color: var(--Gray-700, #344054);

    /* Text sm/semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
}
