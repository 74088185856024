.login-page-contents-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--13, 52px);
  align-self: stretch;

  .login-page-contents-top {
    display: flex;

    flex-direction: column;
    align-items: center;
    gap: var(--7, 28px);
    align-self: stretch;
  }

  .signup-page-check-button-box {
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  .forget-password-button-total-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--2, 8px);
    align-self: stretch;

    .forget-password-button-box {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
    }
  }
}
